import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import DateLogic from "../../util/dateFunctions";
import Slider from "react-slick";
import { Helmet } from "react-helmet";

import "./cart.css";

//add deleteOrder functionality

const dateIndexMap = {
  Mon: 0,
  Tue: 1,
  Wed: 2,
  Thu: 3,
  Fri: 4,
  Sat: 5,
  Sun: 6,
};
const dateIndexMapRe = {
  0: "Sun",
  1: "Mon",
  2: "Tue",
  3: "Wed",
  4: "Thu",
  5: "Fri",
  6: "Sat",
};

export class cart extends Component {
  state = {
    pendingOrders: [],
    awaitingOrders: [],
    loading: false,
    loadingTwo: false,
    time: Date.now(),
    alert: false,
  };

  // deliveryTimes = {
  //   Mon: this.state.deliveryTimesMon,
  //   Tue: this.state.deliveryTimesTue,
  //   Wed: this.state.deliveryTimesWed,
  //   Thu: this.state.deliveryTimesThu,
  //   Fri: this.state.deliveryTimesFri,
  //   Sat: this.state.deliveryTimesSat,
  //   Sun: this.state.deliveryTimesSun,
  // };

  componentDidMount() {
    this.interval = setInterval(
      () => this.getAxios(),
      1000 * 30 * 0.5 //every 1.5 minutes where component is still there
    );
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let payed = params.get("payed");
    let self = this;
    if (payed) {
      this.setState({ alert: true });
      setTimeout(function () {
        self.setState({ alert: false });
      }, 4500);
    }
    // console.log(this.props.user);
    this.setState({ loading: true, loadingTwo: true }, () => {
      this.getAxios();
      this.findAwaiting();
    });
  }

  findAwaiting = () => {
    axios({
      method: "get",
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/orders/awaitingConfirmation`,
      withCredentials: true,
    }).then((response) => {
      if (response.data.status === "success") {
        let awaitingOrders = response.data.data;
        this.setState({ awaitingOrders, loadingTwo: false });
      } else {
        this.setState({ loadingTwo: false });
        alert(
          "There was an error in retrieving the orders of yours that are awaiting confirmation"
        );
      }
    });
  };

  deleteAwaiting = (order, index) => {
    console.log("order used ", order);
    axios({
      method: "delete",
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/orders/awaitingConfirmation`,
      data: { order },
      withCredentials: true,
    }).then((response) => {
      if (response.data.status === "success") {
        let awaitingOrders = [...this.state.awaitingOrders];
        awaitingOrders.splice(index, 1);
        this.setState({ awaitingOrders });
      } else {
        alert(
          `There was an error in deleting this order. Please try again later. Apologies. You can alternatively message the chef that you would like to cancel the order 63${order.chef.phone}!`
        );
      }
    });
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getAxios = () => {
    axios({
      method: "get",
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/orders/me`,
      withCredentials: true,
    }).then((response) => {
      if (response.data.status === "success") {
        let pendingOrders = response.data.data.orders;
        this.setState({ pendingOrders });
        pendingOrders.forEach((order, index) => {
          this.reconfirmInStockAndDate(order, index);
        });
        this.setState({
          loading: false,
        });
      } else {
        this.setState({ loading: false });
        alert("There was an error in retrieving your orders");
      }
    });
  };

  // updateOrder = (order, createdFor, actualDate, index) => {
  //   let data = { order: order, createdFor: createdFor, actualDate: actualDate };
  //   axios({
  //     method: "patch",
  //     url: `${process.env.NODE_ENV === "production" ? process.env.REACT_APP_URL : "http://localhost:8080.com"}/api/v1/orders/cart/${order._id}`,
  //     data: data,
  //     withCredentials: true,
  //   }).then((response) => {
  //     if (response.data.status === "success") {
  //       let pendingOrders = [...this.state.pendingOrders];
  //       if (!response.data.data.order) {
  //         pendingOrders.splice(index, 1);
  //       } else {
  //         pendingOrders[index] = response.data.data.order;
  //       }
  //       this.setState({
  //         pendingOrders: pendingOrders,
  //       });
  //       return;
  //     } else {
  //       alert(
  //         `Your order from ${order.chef.name} may now have some dishes out of stock, OR you may have ordered for a date in the past. Please delete this order if this is the case. Our system tried updating this to the next date but failed. `
  //       );
  //       return;
  //     }
  //   });
  // };

  checkToday = (order) => {
    return order.today;
  };

  getLastTimeBefore = (order) => {
    var time = 10;
    let date = dateIndexMapRe[new Date().getDay()];
    order.chef[`deliveryTimes${date}`].forEach((t) => {
      if (t != null) {
        time = t;
      }
    });
    return time;
  };

  reconfirmInStockAndDate = (order, index) => {
    //HAVE TO DO THIS IN CHECKOUT ALSO OR AUTO REFRESH  else page can j be left open no need auto refresh g.
    //PART 1: IN STOCK
    //CASE PRE : Check for time if today
    let checkToday = this.checkToday(order);
    let inStock = true;
    let stockIndex = dateIndexMap[order.deliveryDate];
    order.dishList.every((dish) => {
      if (dish.dish && !dish.dish.stockQuantity[stockIndex] && !checkToday) {
        inStock = false;
        return false;
      } else if (checkToday) {
        if (!dish.dish.todayStock) {
          inStock = false;
          return false;
        }
      }
    }); //if false then one dish isn't in stock

    //PART 2: DATE LOGIC
    let createdForS = DateLogic.returnNewDate(
      order.deliveryDate,
      true,
      order.chef.dayPrep
    );
    let datearray = createdForS.split("-");
    let doo = new Date(datearray[0], datearray[1] - 1, datearray[2]); //should be 0 tmz any case
    let createdFor = new Date(doo.getTime() + doo.getTimezoneOffset() * -60000);
    // // console.log(
    //   "HERLLLLOO",
    //   datearray.toString(),
    //   doo,
    //   createdFor,
    //   order.createdFor,
    //   createdForS
    // );

    let validDate = createdFor.toISOString() === order.createdFor; //if false then old order

    //CASE 1) if in stock but old order
    if (!validDate && inStock && !checkToday) {
      // console.log("in stock but old order", createdFor, order.createdFor);
      //UPDATE LOGIC USEFUL FOR LATER
      // let actualDate = DateLogic.returnNewDate(
      //   order.deliveryDate,
      //   false,
      //   order.chef.dayPrep
      // );
      // this.updateOrder(order, createdFor, actualDate, index);
      this.deleteOrder(order._id, index);
      // alert(
      //   `Your dish from chef ${order.chef.name} is now out of stock and therefore was deleted. Sorry for the inconvenience.`
      // );
      return;
    } else if (!inStock && !checkToday) {
      // console.log("out of stock", order);
      //CASE 2) if out of stock but current order || if out of stock and old order --> move to next week *mon vs sun day prep req. = sun -> mon = 7
      //UPDATE LOGIC USEFUL FOR LATER
      // createdFor = DateLogic.returnNewDate(order.deliveryDate, true, 7);
      // let actualDate = DateLogic.returnNewDate(order.deliveryDate, false, 7);
      // this.updateOrder(order, createdFor, actualDate, index);
      alert(
        `Your dish from chef ${order.chef.name} is now out of stock and therefore was deleted. Sorry for the inconvenience.`
      );
      this.deleteOrder(order._id, index);
      return;
    } else if (checkToday) {
      if (this.getLastTimeBefore(order) <= new Date().getHours() || !inStock) {
        alert(
          "The order for today seems to be unavailable now. Sorry for the inconvenience"
        );
        this.deleteOrder(order._id, index);
        return;
      }
    } else {
      return;
    }
    //DATE
    //we need to ensure the dishes are still in stock else remove.Also that the date is still valid.  Also confirm in checkout else alert error
  };

  deleteOrder = (id, index) => {
    axios({
      method: "delete",
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/orders/${id}`,
      withCredentials: true,
    }).then((response) => {
      if (response.data.status === "success") {
        let pendingOrders = [...this.state.pendingOrders];
        pendingOrders.splice(index, 1);
        this.setState({ pendingOrders });
      } else {
        alert(
          "There was an error in deleting this order. Please try again later. Apologies!"
        );
      }
    });
  };

  settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  // componentDidUpdate(prevProps) {
  //   if (prevProps.date !== this.props.date) {
  //     this.getAxios();
  //   }
  // }
  render() {
    if (!this.state.loading && !this.state.loadingTwo) {
      return (
        <section className="cart_section">
          <Helmet>
            <title>tizaar: Your Cart</title>
            <meta
              name="description"
              content="Tickle your taste buds at tizaar's food bazaar and unconver hidden food delights right in your very neighborhood!"
            />
          </Helmet>
          {this.state.alert ? (
            <div className="payed_alert">
              Your payment was succesfully recieved
            </div>
          ) : (
            <div></div>
          )}
          <div className="container_homechef" style={{ textAlign: "center" }}>
            <h1>{this.state.pendingOrders.length === 0 ? "" : "Cart"}</h1>
            <p className="note" style={{ width: "100%", maxWidth: "100%" }}>
              <b>
                Click the post it note to review and checkout your order from a
                chef!
              </b>
            </p>
            <div className="card_wrapper">
              {this.state.pendingOrders.map((order, i) => {
                return (
                  <div style={{ padding: "20px" }} key={order._id}>
                    <div
                      className="flex_start cart_flex_start"
                      onClick={() => this.deleteOrder(order._id, i)}
                    >
                      <i className="fas fa-times red_cross_c"></i>
                      <p className="red_cart_cross">Delete Order</p>
                    </div>

                    <Link
                      className="cart_link_fix"
                      to={{
                        pathname: `/checkout/${order._id}`,
                        aboutProps: {
                          pendingOrder: order,
                          user: this.props.user,
                        },
                      }}
                    >
                      <div className="receipt_card">
                        <div className="flex_cont">
                          <p style={{ color: "red" }}>
                            FOR {order.deliveryDate},<b> {order.actualDate}</b>
                          </p>
                        </div>
                        <div className="header_receipt">
                          <h1 className="chef_name_receipt">
                            CHEF {order.chef.name}
                          </h1>{" "}
                          <div
                            className="chef_image_receipt image"
                            style={{
                              backgroundImage: `url(${order.chef.userPhoto})`,
                            }}
                          ></div>
                        </div>
                        <div className="order_and_quantity">
                          {order.dishList.map((dish, i) => {
                            {
                              // console.log(dish);
                            }
                            return (
                              <div key={i}>
                                <div className="order_and_quantity_header">
                                  <div className="dish_name_receipt">
                                    <p className="order_name_content">
                                      {dish.dish.name} |
                                    </p>
                                  </div>
                                  <div className="dish_quantity_receipt">
                                    <p className="order_name_content">
                                      {dish.quantity} Orders
                                    </p>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                          {/* <button className="enter_checkout btn col-4">
                        Enter Checkout
                        {/* LINK HERE WITH ORDER IN PROP 
                      </button> */}
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
            {this.state.awaitingOrders.length > 0 ? (
              <div
                className="container_homechef"
                style={{ textAlign: "center" }}
              >
                <h1>Pending Orders</h1>
                <div className="card_wrapper">
                  {this.state.awaitingOrders.map((order, i) => {
                    if (order.chef)
                      return (
                        <div style={{ padding: "20px" }} key={order._id}>
                          {/* <div
                            className="flex_start cart_flex_start"
                            onClick={() => this.deleteAwaiting(order, i)}
                          >
                            <i className="fas fa-times red_cross_c"></i>
                            <p className="red_cart_cross">Delete Order</p>
                          </div> */}

                          <div className="cart_link_fix">
                            <div className="receipt_card">
                              <div className="flex_cont">
                                <p style={{ color: "red" }}>
                                  FOR {order.deliveryDate},
                                  <b> {order.actualDate}</b>
                                </p>
                              </div>
                              <div className="header_receipt">
                                <h1 className="chef_name_receipt">
                                  CHEF {order.chef.name}
                                </h1>{" "}
                                <div
                                  className="chef_image_receipt image"
                                  style={{
                                    backgroundImage: `url(${order.chef.userPhoto})`,
                                  }}
                                ></div>
                              </div>
                              <div className="order_and_quantity">
                                {order.dishList.map((dish, i) => {
                                  {
                                    // console.log(dish);
                                  }
                                  return (
                                    <div key={i}>
                                      <div className="order_and_quantity_header">
                                        <div className="dish_name_receipt">
                                          <p className="order_name_content">
                                            {dish.dish.name} |
                                          </p>
                                        </div>
                                        <div className="dish_quantity_receipt">
                                          <p className="order_name_content">
                                            {dish.quantity} Orders
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                                {/* <button className="enter_checkout btn col-4">
                        Enter Checkout
                        {/* LINK HERE WITH ORDER IN PROP 
                        </button> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    return null;
                  })}
                </div>
              </div>
            ) : (
              <p>No orders awaiting chef confirmation at the moment</p>
            )}
          </div>
        </section>
      );
    } else {
      return (
        <div className="fullheight">
          <div
            className="chef_float"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/landing/chef.png)`,
            }}
          ></div>
        </div>
      );
    }
  }
}

export default cart;
