import React, { Component } from "react";
import DishInput from "./dishInput";
import FormCreate from "./formCreate";
import Loader from "react-loader-spinner";
import {
  FacebookShareButton,
  WhatsappShareButton,
  FacebookIcon,
  WhatsappIcon,
} from "react-share";
import "./MyMenu.css";
import axios from "axios";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { Helmet } from "react-helmet";

export class MyMenu extends Component {
  state = {
    user: { ...this.props.user },
    bioEdit: false,
    showEditPopup: false,
    loading: true,
    submissionLoad: false,
    nameEdit: false,
    addressEdit: false,
    today: this.props.user ? this.props.user.today : false,
    uploadMain: null,
    uploadBrand: null,
    mainLoad: false,
    brandLoad: false,
    steps: [
      {
        target: ".mini_address_input",
        content:
          "This is the general location/mini address that we will show on the bazaar page. People are more likely to buy from you if you are close to them!",
      },
      {
        target: ".flex_container",
        content:
          "Select the days in which you will have a menu to order from. These are the dates for which you can list dishes below for.",
      },
      {
        target: ".edit_button_bio_name",
        content: "You can edit your name here.",
      },
      {
        target: ".descriptorr",
        content:
          "Tell the customers your story! I.e where you learnt to cook? Where you came from? Your cooking background? Please don't clutter this with links.",
      },
      {
        target: ".edit_photo_main",
        content:
          "Edit your photo here. Please put a photo of yourself! We are trying to build a community so it helps to make the platform as personable as possible.",
      },
      {
        target: ".edit_photo_brand",
        content:
          "If you have a brand photo include it here! This is not necessary!!!",
      },
      {
        target: ".social_share",
        content:
          "You can share your menu across whatsapp groups and on facebook at the click of a button. Please support your fellow homechefs by sharing their menus on their pages!",
      },
      {
        target: ".add_dish_button",
        content:
          "You can create a dish by pressing this button. A popup will prompt you to fill the rest of the details!",
      },
      {
        target: ".edit_dish_button",
        content: "Click this button to edit a dish!",
      },
    ],
  };

  dayMap = {
    Mon: 0,
    Tue: 1,
    Wed: 2,
    Thu: 3,
    Fri: 4,
    Sat: 5,
    Sun: 6,
  };

  defaultDish = {
    photo: "",
    name: "",
    deliveryDates: ["", "", "", "", "", "", ""],
    tags: [],
    description: "",
    price: 0,
    allergyDescription: "",
    stockQuantity: [false, false, false, false, false, false, false],
    groupPromo: false,
    servingSize: 1,
    unit: "",
    price: "",
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({ loading: true });
    axios({
      method: "get",
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/users/menu/${this.props.user.id}`,
      withCredentials: true,
    }).then((response) => {
      if (response.data.status === "success") {
        // console.log("GETTING MENU", response.data.data.user.dayPrep);
        let user = response.data.data.user;
        this.setState({ user, loading: false });
      } else {
        alert("There was an error getting the chefs menu!");
      }
    });
  }

  componentDidUpdate() {
    if (this.state.bioEdit) {
      const bioInput = document.querySelector(".auto-bio-input");
      // console.log(bioInput);
      bioInput.value = this.state.user.description;
    }
  }
  //CHECK FOR AUTH! ELSE RENDER 404 ERROR. User should be null at the start and then retrieved thru JWT here. Do this in app.js and pass user as prop.
  // this.setState({user: this.props.user}) IN ALL PROTECTED ROUTES! No need for state even. Loading alr done.

  // grabPhoto = (e) => {
  //   // var preview = document.querySelector(".chef_image_menu_edit");
  //   var image = e.target.files[0];
  //   // console.log(image);
  //   if (image) this.reader.readAsDataURL(image);
  //   // const bgUrl = URL.createObjectURL(image);
  //   // // console.log(bgUrl);
  //   // preview.style.backgroundImage = `url${bgUrl}`;
  //   //AWS?
  // };

  uploadUserPhoto = (e) => {
    var bodyFormData = new FormData();
    var imageFile = e.target.files[0];
    this.setState({ mainLoad: true });
    let self = this;
    bodyFormData.append("image", imageFile);
    axios({
      method: "post",
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/images/profile`,
      data: bodyFormData,
      headers: {
        "Content-Type":
          "multipart/form-data; boundary=<calculated when request is sent>",
      },
      withCredentials: true,
    })
      .then(function (response) {
        let imageUrl = response.data.imageUrl; //response.data.imageKey
        let user = { ...self.state.user };
        user.userPhoto = imageUrl;
        // console.log(imageUrl);
        self.setState({ user, uploadMain: Date.now(), mainLoad: false });
      })
      .catch(function (response) {
        this.setState({ mainLoad: false });
        console.log(response);
        alert("There was an error in uploading the image");
      });
  };

  uploadBrandPhoto = (e) => {
    // console.log("I AM HERE");
    var bodyFormData = new FormData();
    var imageFile = e.target.files[0];
    this.setState({ brandLoad: true });
    let self = this;
    bodyFormData.append("image", imageFile);
    axios({
      method: "post",
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/images/brand`,
      data: bodyFormData,
      headers: {
        "Content-Type":
          "multipart/form-data; boundary=<calculated when request is sent>",
      },
      withCredentials: true,
    })
      .then(function (response) {
        let imageUrl = response.data.imageUrl;
        let user = { ...self.state.user };
        user.brandPhoto = imageUrl;
        self.setState({ user, uploadBrand: Date.now(), brandLoad: false });
      })
      .catch(function (response) {
        this.setState({ brandLoad: false });
        console.log(response);
        alert("There was an error in uploading the image");
      });
  };

  updateDescription = (e, description) => {
    let user = { ...this.state.user };
    // console.log(e.target.value);
    description
      ? (user.description = e.target.value)
      : (user.name = e.target.value);

    // console.log(user);
    this.setState({ user, showEditPopup: false });
  };

  removeDish = (index) => {
    let user = { ...this.state.user };
    user.dishes.splice(index, 1);
    // console.log(user.dishes);
    this.setState({ user });
    // this.props.updateUser(user);
    //DELETE REQUEST HERE!
  };

  addDish = (dish) => {
    let user = { ...this.state.user };
    this.setState({ submissionLoad: true });
    // this.setState({ user, showEditPopup: false }); //might not even need to call this re-render?
    // // console.log(this.state.user);

    axios({
      method: "post",
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/dishes`,
      data: dish, //this is an update of all fields in the dish including those not necessarily updated. SLOW! Add only a field for updated ones.
      withCredentials: true,
    }).then((response) => {
      if (response.data.status === "success") {
        this.setState({
          user: response.data.data.user,
          showEditPopup: false,
          submissionLoad: false,
        });
        // this.props.updateUser(user); NOT NECESSARY
        // console.log("The update was succesful");
      } else {
        alert(
          "There was an error in creating the dish. Please contact mahtanir@wharton.upenn.edu and sorry for any inconvenience."
        );
      }
    });

    //if (edit) PATCH REQUEST, ELSE, POST REQUEST to add new dish to this user!
    //Id doesn't change for patch, so will only need to patch dish, not user! For post though need to add to child reference and post new.
    //Parent referencing easy for child need to create new array = dishes.push(dish) and do path request to user (but only iDs). Bookings parent referencing.
  };

  setEdit = () => {
    this.setState({ showEditPopup: false });
  };

  submitDescription = (description) => {
    //PATCH REQUEST! State has already been updated so simply pass the whole object in or the description alongside id? CHECK NATOURS!
    let data = description
      ? { description: this.state.user.description }
      : { name: this.state.user.name };
    description
      ? this.setState({ bioEdit: false })
      : this.setState({ nameEdit: false });
    axios({
      method: "patch",
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/users/me`,
      data: data,
      withCredentials: true,
    }).then((response) => {
      if (response.data.status === "success") {
        // description
        //   ? this.setState({ bioEdit: false })
        //   : this.setState({ nameEdit: false });
      } else {
        // description
        //   ? this.setState({ bioEdit: true })
        //   : this.setState({ nameEdit: true });
        alert(
          "There was an error and the description/name was not saved. Please refresh and try again later."
        );
      }
    });
    //have to pass this back to parent
  };

  removeElement = (array, day) => {
    let returned = [];
    // console.log(day);
    Array.from(array).forEach((e) => {
      if (e !== day) {
        returned.push(e);
      }
    });
    // console.log(returned);
    return returned;
  };

  updateMenuDates = (day) => {
    let user = { ...this.state.user };
    let menuDates = user.menuDates;
    if (menuDates === undefined || menuDates === null) {
      menuDates = [];
    }
    // menuDates[day] =
    //   menuDates[day] === null || menuDates[day] === undefined || !menuDates[day]
    //     ? true
    //     : false;
    let prevArr = Array.from(menuDates);
    if (menuDates.includes(day)) {
      menuDates = this.removeElement(menuDates, day);
    } else {
      menuDates.push(day);
    }
    user.menuDates = menuDates; //response.data.data.user.menuDwtees
    this.setState({ user });
    //AXIOS CALL
    axios({
      method: "patch",
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/users/me`,
      data: { menuDates },
      withCredentials: true,
    }).then((response) => {
      if (response.data.status === "success") {
        // console.log("success");
      } else {
        user.menuDates = prevArr;
        this.setState({ user });
        alert(
          "There was an error and the menuDates were not saved. Please try again later and contact mahtanir@wharton.upenn.edu"
        );
      }
    });
  };

  updateDish = (dish, index) => {
    let user = { ...this.state.user, submissionLoad: true };
    //UPDATE DISH AXIOS CALL
    user.dishes[index] = dish; //updating USER getting updated DISH!
    axios({
      method: "patch",
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/dishes/${dish.id}`,
      data: dish, //this is an update of all fields in the dish including those not necessarily updated. SLOW! Add only a field for updated ones.
      withCredentials: true,
    }).then((response) => {
      if (response.data.status === "success") {
        // this.props.updateUser(user);
        this.setState({ user, submissionLoad: false });
        // console.log("The update was succesful");
      } else {
        alert(
          "There was an error in updating the dish. Please contact mahtanir@wharton.upenn.edu and sorry for any inconvenience."
        );
      }
    });
  };

  updateMiniAddress = (val) => {
    // console.log("ADDRESS", val);
    let user = { ...this.state.user };
    user.miniAddress = val;
    this.setState({ user });
  };

  submitMiniAddress = () => {
    // console.log("HERE @ MINI ADDRESS");
    let data = { miniAddress: this.state.user.miniAddress };
    this.setState({ addressEdit: false });
    axios({
      method: "patch",
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/users/me`,
      data: data,
      withCredentials: true,
    }).then((response) => {
      if (response.data.status === "success") {
        // this.setState({ addressEdit: false });
      } else {
        alert(
          "There was an error and the miniAddress was not saved. Please refresh and try again in a few minutes."
        );
      }
    });
    //have to pass this back to parent
  };

  handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;
    let guides = this.state.user.guides;
    guides[1] = false;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      axios({
        method: "patch",
        url: `${
          process.env.NODE_ENV === "production"
            ? process.env.REACT_APP_URL
            : "http://localhost:8080"
        }/api/v1/users/me`,
        data: { guides },
        withCredentials: true,
      }).then((response) => {
        if (response.data.status === "success") {
          let user = { ...this.state.user };
          user.guides = guides;
          this.setState({ user });
        } else {
          // console.log("error getting rid of guide");
        }
      });
    }
  };

  updateToday = () => {
    let user = { ...this.state.user };
    let today = !this.state.today;
    user.today = today;
    this.setState({ user, today });
    axios({
      method: "patch",
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/users/updateToday`,
      data: { today },
      withCredentials: true,
    })
      .then((response) => {
        console.log("success");
      })
      .catch(() =>
        alert(
          "There was an error. Your selection for today has not been registered."
        )
      );
  };

  render() {
    const { steps } = this.state;
    let menuDates = Array.from(this.state.user.menuDates);
    if (menuDates === undefined || menuDates === null) {
      menuDates = [];
    }
    // console.log(this.state.user);
    if (!this.state.loading) {
      return (
        <section className="chef_menu">
          <Helmet>
            <title>tizaar - My Menu</title>
            <meta
              name="description"
              content="Tickle your taste buds at tizaar's food bazaar and unconver hidden food delights right in your very neighborhood!"
            />
          </Helmet>
          {this.state.user.guides[1] ? (
            <Joyride
              steps={steps}
              continuous
              showProgress
              showSkipButton
              callback={this.handleJoyrideCallback}
              styles={{
                options: {
                  arrowColor: "#fff",
                  backgroundColor: "#fff",
                  beaconSize: 50,
                  overlayColor: "rgba(0, 0, 0, 0.5)",
                  primaryColor: "#ffbb3a",
                  spotlightShadow: "0 0 15px rgba(0, 0, 0, 0.5)",
                  textColor: "#333",
                  width: undefined,
                  zIndex: 100,
                },
              }}
              // beaconComponent={BeaconComponent}
            />
          ) : (
            <div></div>
          )}
          <div className="container_homechef">
            <div className="menu_main">
              <div className="left_main_container">
                <div style={{ width: "100%", position: "relative" }}>
                  <div
                    className={
                      this.state.user.rectangle
                        ? "chef_image_menu_edit image rectangle"
                        : "chef_image_menu_edit image"
                    }
                    style={{
                      backgroundImage: `url(${this.state.user.userPhoto}?${this.state.uploadMain})`, //?${Date.now()}
                    }}
                  >
                    {this.state.mainLoad ? (
                      <div className="edit_photo">
                        <Loader
                          type="Puff"
                          color="#FFA500"
                          height={40}
                          width={40}
                        />
                      </div>
                    ) : (
                      <label htmlFor="upload_img">
                        <i className="far fa-images edit_photo edit_photo_main"></i>
                      </label>
                    )}
                    <input
                      type="file"
                      id="upload_img"
                      onChange={this.uploadUserPhoto}
                    />
                    {this.state.mainLoad ? "Loading..." : "Edit Image"}
                  </div>
                  {/* <div className="brand_photoh">
                    <div
                      className={
                        this.state.user.contain
                          ? "brand_photo_image image contain"
                          : "brand_photo_image image"
                      }
                      style={{
                        backgroundImage: `url(${this.state.user.brandPhoto}?${this.state.uploadBrand})`,
                        backgroundColor: "white",
                      }}
                    >
                      {this.state.brandLoad ? (
                        <div className="edit_photo">
                          <Loader
                            type="Puff"
                            color="#FFA500"
                            height={40}
                            width={40}
                          />
                        </div>
                      ) : (
                        <label htmlFor="upload_img_brand">
                          <i
                            className="far fa-images  edit_photo edit_photo_brand"
                            style={{
                              fontSize: "30px",
                              color: "black",
                              cursor: "pointer",
                            }}
                          ></i>
                        </label>
                      )}
                      <input
                        type="file"
                        id="upload_img_brand"
                        onChange={this.uploadBrandPhoto}
                      />
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="right_main_container">
                <h1
                  className="chef_tag"
                  style={{
                    color: "#f9603f",
                    fontFamily: "landingFontTwo",
                    textAlign: "start",
                    marginTop: "20px",
                  }}
                >
                  CHEF
                </h1>
                {!this.state.nameEdit ? (
                  <h1
                    className="chef_name_bold"
                    style={{
                      fontFamily: "p-bold",
                      color: "white",
                      textAlign: "start",
                      fontSize: "50px",
                      textTransform: "uppercase",
                    }}
                  >
                    {this.state.user.name}'S{" "}
                    <span
                      style={{
                        color: "#fbd36c",
                        fontFamily: "landingFontTwo",
                      }}
                    >
                      MENU
                    </span>
                  </h1>
                ) : (
                  <textarea
                    type="text"
                    className="auto-bio-input auto-name-input"
                    style={{ fontFamily: "landingFontTwo", fontSize: "50px" }}
                    maxLength="400"
                    onChange={(e) => this.updateDescription(e, false)}
                  ></textarea>
                )}
                {!this.state.nameEdit ? (
                  <div
                    className="edit_button_bio edit_button_bio_name"
                    onClick={() => {
                      this.setState({ nameEdit: true });
                    }}
                  >
                    <i className="far fa-edit"></i>
                    Edit your name
                  </div>
                ) : (
                  <div
                    className="edit_button_bio green"
                    onClick={(e) => this.submitDescription(false)}
                  >
                    <i
                      className="far fa-check-circle"
                      style={{ color: "green" }}
                    ></i>
                    Save
                  </div>
                )}
                <div className="chef_cuisine">
                  CUISINES: {this.state.user.cuisine.join(", ")}
                </div>
                {!this.state.addressEdit ? (
                  <div style={{ textAlign: "left" }}>
                    <p className="mini_address_input">
                      MINI ADDRESS: {this.state.user.miniAddress}
                    </p>
                    <div
                      className="edit_button_bio edit_button_bio_descr"
                      onClick={() => {
                        this.setState({ addressEdit: true });
                      }}
                    >
                      <i className="far fa-edit"></i>
                      Edit the (mini) address that's displayed in the bazaar
                      page. (200 characters max)
                    </div>
                  </div>
                ) : (
                  <div style={{ textAlign: "left" }}>
                    <textarea
                      type="text"
                      className="auto-address-input"
                      maxLength="200"
                      placeholder="Village, City OR Building name, City"
                      onChange={(e) => this.updateMiniAddress(e.target.value)}
                    ></textarea>
                    <div
                      className="edit_button_bio green"
                      onClick={this.submitMiniAddress}
                    >
                      <i
                        className="far fa-check-circle"
                        style={{ color: "green" }}
                      ></i>
                      Save (Mini) Address
                    </div>
                  </div>
                )}
                {/* BIO EDIT */}
                {this.state.bioEdit ? (
                  <textarea
                    type="text"
                    className="auto-bio-input"
                    maxLength="400"
                    onChange={(e) => this.updateDescription(e, true)}
                  ></textarea>
                ) : (
                  <p className="auto-bio">{this.state.user.description}</p>
                )}
                {/* UPDATE EDIT DIVS! */}
                {this.state.bioEdit ? (
                  <div
                    className="edit_button_bio green"
                    onClick={(e) => this.submitDescription(true)}
                  >
                    <i
                      className="far fa-check-circle"
                      style={{ color: "green" }}
                    ></i>
                    Save
                  </div>
                ) : (
                  <div
                    className="edit_button_bio edit_button_bio_descr descriptorr"
                    onClick={() => {
                      this.setState({ bioEdit: true });
                    }}
                  >
                    <i className="far fa-edit"></i>
                    Edit your bio/description (400 characters max)
                  </div>
                )}
                <div className="flex_container">
                  <p style={{ fontFamily: "landingFontTwo", fontSize: "22px" }}>
                    <b>SELECT MENU DATES</b>
                  </p>
                  <div className="days">
                    <div
                      className={
                        menuDates.includes("Mon")
                          ? "date_bubble mymenu_bubble active"
                          : "date_bubble mymenu_bubble"
                      }
                      onClick={() => this.updateMenuDates("Mon")}
                    >
                      <p>Mon</p>
                    </div>
                    <div
                      className={
                        menuDates.includes("Tue")
                          ? "date_bubble mymenu_bubble active"
                          : "date_bubble mymenu_bubble"
                      }
                      onClick={() => this.updateMenuDates("Tue")}
                    >
                      <p>Tue</p>
                    </div>
                    <div
                      className={
                        menuDates.includes("Wed")
                          ? "date_bubble mymenu_bubble active"
                          : "date_bubble mymenu_bubble"
                      }
                      onClick={() => this.updateMenuDates("Wed")}
                    >
                      <p>Wed</p>
                    </div>
                    <div
                      className={
                        menuDates.includes("Thu")
                          ? "date_bubble mymenu_bubble active"
                          : "date_bubble mymenu_bubble"
                      }
                      onClick={() => this.updateMenuDates("Thu")}
                    >
                      <p>Thu</p>
                    </div>
                    <div
                      className={
                        menuDates.includes("Fri")
                          ? "date_bubble mymenu_bubble active"
                          : "date_bubble mymenu_bubble"
                      }
                      onClick={() => this.updateMenuDates("Fri")}
                    >
                      <p>Fri</p>
                    </div>
                    <div
                      className={
                        menuDates.includes("Sat")
                          ? "date_bubble mymenu_bubble active"
                          : "date_bubble mymenu_bubble"
                      }
                      onClick={() => this.updateMenuDates("Sat")}
                    >
                      <p>Sat</p>
                    </div>
                    <div
                      className={
                        menuDates.includes("Sun")
                          ? "date_bubble mymenu_bubble active"
                          : "date_bubble mymenu_bubble"
                      }
                      onClick={() => this.updateMenuDates("Sun")}
                    >
                      <p>Sun</p>
                    </div>
                    {/* <div
                      className={
                        this.state.user.today
                          ? "date_bubble mymenu_bubble active"
                          : "date_bubble mymenu_bubble"
                      }
                      onClick={this.updateToday}
                    >
                      <p>Today</p>
                    </div> */}
                  </div>
                </div>
                <div className="flex_start">
                  <span className=" dairy spicy_key flex_start">
                    Dairy:{" "}
                    <img
                      src={`${process.env.PUBLIC_URL}/menu/dairy.svg`}
                      style={{
                        width: "16px",
                        height: "16px",
                        marginLeft: "5px",
                      }}
                      alt=""
                    />
                  </span>
                  <span
                    className="spicy_key flex_start"
                    style={{ marginLeft: "10px" }}
                  >
                    Spicy:{" "}
                    <i
                      className="fas fa-pepper-hot"
                      style={{ color: "red" }}
                    ></i>
                  </span>
                  <span
                    className="vegetarian flex-start spicy_key"
                    style={{ marginLeft: "10px" }}
                  >
                    Vegetarian:{" "}
                    <i className="fas fa-leaf" style={{ color: "green" }}></i>
                  </span>
                  <span
                    className="group_order spicy_key flex_start"
                    style={{ marginLeft: "10px" }}
                  >
                    Group Promo Order:{" "}
                    <i
                      className="fas fa-users"
                      style={{ color: "#fcdb03" }}
                      title="group promo"
                    ></i>
                  </span>
                </div>
              </div>
              <div className="social_share">
                {" "}
                Share Menu
                <FacebookShareButton
                  url={`https://www.tizaar.com/menu/${this.state.user.id}`}
                >
                  <FacebookIcon
                    size={"1.5rem"} // You can use rem value instead of numbers
                    round
                    className="fab"
                  />
                </FacebookShareButton>
                <WhatsappShareButton
                  url={`https://www.tizaar.com/menu/${this.state.user.id}`}
                >
                  <WhatsappIcon
                    size={"1.5rem"} // You can use rem value instead of numbers
                    round
                    className="fab"
                  />
                </WhatsappShareButton>
                {/* INSTA! */}
              </div>
              {/* ADD DISH HERE */}
              <div
                className="add_dish_button"
                onClick={() => {
                  this.setState({ showEditPopup: true });
                }}
              >
                Add a new menu item!
              </div>
              {/* {console.log("DEFAULT DISH", this.defaultDish)} */}
              <FormCreate
                today={this.state.user.today}
                pantry={this.state.user.pantry}
                menuDates={menuDates}
                dish={this.defaultDish}
                showEditPopup={this.state.showEditPopup}
                submissionLoad={this.state.submissionLoad}
                updateDish={this.addDish}
                cancelForm={this.setEdit}
                edit={false} //create instead
              />
            </div>
            {/* MENU ITEMS */}
            <section className="menu_items">
              <h1 className="menu_item_title">MENU</h1>
              <div className="menu_items_cont">
                {this.state.user.dishes.map((dish, i) => {
                  return (
                    <DishInput
                      key={i}
                      today={this.state.user.today}
                      dish={dish}
                      index={i}
                      submissionLoad={this.state.submissionLoad}
                      remove={this.removeDish}
                      menuDates={menuDates}
                      deliveryTimesMon={this.state.user.deliveryTimesMon}
                      deliveryTimesTue={this.state.user.deliveryTimesTue}
                      deliveryTimesWed={this.state.user.deliveryTimesWed}
                      deliveryTimesThu={this.state.user.deliveryTimesThu}
                      deliveryTimesFri={this.state.user.deliveryTimesFri}
                      deliveryTimesSat={this.state.user.deliveryTimesSat}
                      deliveryTimesSun={this.state.user.deliveryTimesSun}
                      pantry={this.state.user.pantry}
                      dayPrep={this.state.user.dayPrep}
                      updateDish={this.updateDish}
                    />
                  );
                })}
                {/* ADD DISH HERE through edit, use dishInput editor AND post req to update user object. */}
              </div>
            </section>
          </div>
        </section>
      );
    } else {
      return (
        <div className="fullheight">
          <div
            className="chef_float"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/landing/chef.png)`,
            }}
          ></div>
        </div>
      );
    }
  }
}

export default MyMenu;
