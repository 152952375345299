import React, { Component } from "react";
import DateLogic from "../../util/dateFunctions";
import "./checkout.css";
import axios from "axios";
import Lalamove from "../../util/lalamove";
import Loader from "react-loader-spinner";
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import LocationInput from "../locationInput/locationInput";

const dateIndexMapRe = {
  0: "Sun",
  1: "Mon",
  2: "Tue",
  3: "Wed",
  4: "Thu",
  5: "Fri",
  6: "Sat",
};

const dateMap = {
  Sun: 0,
  Mon: 1,
  Tue: 2,
  Wed: 3,
  Thu: 4,
  Fri: 5,
  Sat: 6,
};

export class checkout extends Component {
  state = {
    pendingOrder:
      this.props.location && this.props.location.aboutProps
        ? this.props.location.aboutProps.pendingOrder
        : null, //ok to modify direct.
    creditDebit: false,
    // orderDate: [],
    phone: this.props.user.phone,
    address:
      this.props.location &&
      this.props.location.aboutProps &&
      this.props.location.aboutProps.pendingOrder
        ? this.props.location.aboutProps.pendingOrder.address
        : this.props.user.address,
    lat: null,
    lng: null,

    loading: false,
    delivery:
      this.props.location &&
      this.props.location.aboutProps &&
      this.props.location.aboutProps.pendingOrder &&
      this.props.location.aboutProps.pendingOrder.chef
        ? !this.props.location.aboutProps.pendingOrder.chef.pickup
        : null, //ok to modify direct.,
    deliveryFee: 0,
    aptNumber: this.props.user.aptNumber,
    deliveryCurrency: "",
    digital: false,
    redirect: false,
    loading: !(this.props.location && this.props.location.aboutProps),
    submitLoading: false,
    redirectToday: false,
    note: null,
    warn: false,
    quoteLoading: false,
  };

  dateMap = {
    Mon: "Monday",
    Tue: "Tuesday",
    Wed: "Wednesday",
    Thu: "Thursday",
    Fri: "Friday",
  };

  componentDidMount() {
    // this.getDeliveryQuote();
    this.interval = setInterval(
      () => window.location.reload(),
      1000 * 60 * 7 //every 1.5 minutes where component is still there
    );
    // console.log("YOOOO WHAT'S UP", this.props.match.params.id);
    if (
      !this.props.location ||
      !this.props.location.aboutProps ||
      !this.props.location.aboutProps.pendingOrder
    ) {
      axios({
        method: "get",
        url: `${
          process.env.NODE_ENV === "production"
            ? process.env.REACT_APP_URL
            : "http://localhost:8080"
        }/api/v1/orders/${this.props.match.params.id}`,
        withCredentials: true,
      })
        .then(async (response) => {
          // console.log("response", response);
          await this.setState({
            pendingOrder: response.data.data.order,
            address: response.data.data.order.address,
            loading: false,
            note: response.data.data.order.note,
            delivery: response.data.data.order
              ? !response.data.data.order.chef.pickup
              : true,
          });
          console.log("retrieved pending", this.state.pendingOrder);
          this.getDeliveryQuote();
        })
        .catch((err) => {
          alert(
            "there was an error in getting the checkout page for the order, please try again."
          );
          this.setState({ redirect: true });
        });
    } else {
      // console.log("USER", this.props.user);
      this.getDeliveryQuote();
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  componentDidUpdate() {}

  convertTime = (time) => {
    let timeCopy = time;
    let timeTo = timeCopy + 2;
    let stamp = "am";
    let stampCopy = "am";
    if (time >= 12) {
      stamp = "pm";
      if (time > 12) timeCopy = time - 12;
    }
    if (timeTo >= 12) {
      stampCopy = "pm";
      if (timeTo > 12) timeTo = timeTo - 12;
    }
    return `${timeCopy}${stamp} - ${timeTo}${stampCopy}`;
  };

  updateSelectedTime = (time) => {
    //NEED TO CHANGE THIS!!!
    let pendingOrder = { ...this.state.pendingOrder };
    pendingOrder.selectedTime = time;
    this.setState({ pendingOrder });
  };

  calcTotalPrice = () => {
    let totalPrice = 0;
    this.state.pendingOrder.dishList.forEach((dish) => {
      let val = dish.quantity * dish.dish.price;
      totalPrice = totalPrice + val;
    });
    return (
      totalPrice +
      (this.state.deliveryFee && this.state.delivery
        ? this.state.deliveryFee * 1
        : 0)
    );
  };

  setLocation = (address, lat, lng) => {
    let self = this;
    let pendingOrder = { ...this.state.pendingOrder };
    pendingOrder.address = address;
    pendingOrder.lat = lat;
    pendingOrder.lng = lng;
    this.setState({ address, lat, lng, pendingOrder }, () => {
      let pendingOrder = { ...this.state.pendingOrder };
      pendingOrder.address = address;
      pendingOrder.lat = lat;
      pendingOrder.lng = lng;
      console.log(this.state.address, this.state.lat, this.state.lng);
      self.getDeliveryQuote();
    });
  };

  consoleLogs = (time, date, dateNumberDouble) => {
    // console.log("HOUR: " + time);
    // console.log("Present HOUR: " + new Date().getHours());
    // console.log("DAY: " + dateMap[date]);
    // console.log("PRESENT DAY: " + new Date().getDay());
    // console.log("DATE: " + dateNumberDouble);
    // console.log("PRESENT DATE: " + new Date().getUTCDate());
    // console.log("_________________________");
  };

  validateTime = (time) => {
    if (!time) {
      return false;
    }

    // this.consoleLogs(time, day, dateNumberDouble);
    return (
      time > new Date().getHours() || !this.state.pendingOrder.today //make sure that this also works for single days.
    );
  };

  getDeliveryQuote = () => {
    //could be losing or gaining money as order made in advance

    /*SPEEDY CODE*/
    axios({
      method: "get",
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/orders/delivery/${this.state.pendingOrder._id}?lat=${
        this.state.lat
      }&lng=${this.state.lng}&address=${this.state.address}`,
      withCredentials: true,
    })
      .then(async (response) => {
        console.log("DATA", response.data.data);
        await this.setState({ deliveryFee: response.data.data });
      })
      .catch(
        this.setState({
          deliveryFee: "There was an error calculating the delivery fee",
          quoteLoading: false,
        })
      );

    /* LALAMOVE CODE
    let lala = new Lalamove();
    let quote = lala.getQuotation(this.state.pendingOrder, this.props.user);
    let pendingOrder = { ...this.state.pendingOrder };
    pendingOrder.deliveryFee = quote.totalFee;
    pendingOrder.deliveryCurrency = quote.totalFeeCurrency;
    this.setState({ pendingOrder });*/
  };

  getLastTimeBefore = () => {
    var time = 10;
    let date = dateIndexMapRe[new Date().getDay()];
    this.state.pendingOrder.chef[`deliveryTimes${date}`].forEach((t) => {
      if (t != null) {
        time = t;
      }
    });
    return time;
  };

  updateMe = (data) => {
    axios({
      method: "patch",
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/users/me`,
      data: data,
      withCredentials: true,
    }).then((response) => {
      if (response.data.status === "success") {
        this.setState({ loading: false });
        this.props.setUser(response.data.data.user);
        console.log(
          response.data.data.user,
          "Update for phone and address succesful"
        );
      } else {
        this.setState({ loading: false });
        alert(
          "There was an error with the checkout and it did not go through!"
        );
      }
    });
  };

  axiosUpdate = () => {
    //CHECK THIS!
    if (this.state.pendingOrder.today) {
      let lastTime = this.getLastTimeBefore();
      if (lastTime <= new Date().getHours()) {
        alert(
          "Unfortunately the order is now unavailable. Perhaps the chef just said they ran out of stock."
        );
        return;
      }
    }
    //THEY CAN UPDATE PHONE
    if (this.state.phone !== this.props.user.phone) {
      this.updateMe({ phone: this.state.phone });
    }
    let data = {};
    data.deliveryMode = this.state.delivery ? "delivery" : "pickup";
    data.deliveryFee = this.state.deliveryFee;
    data.deliveryCurrency = this.state.deliveryCurrency;
    data.phone = this.state.phone;
    data.note = this.state.note;
    data.digital = this.state.digital;
    data.selectedTime = this.state.pendingOrder.selectedTime;
    if (this.state.lat && this.state.lng) {
      //if lat lng not null then changed address
      data.lat = this.state.lat;
      data.lng = this.state.lng;
      data.address = this.state.address;
    }
    // if (!this.state.pendingOrder.address) data.address = this.state.address;
    // if (!this.state.pendingOrder.lat && this.props.user.location)
    //   data.lat = this.props.user.location.coordinates[1];
    // if (!this.state.pendingOrder.lng && this.props.user.location)
    //   data.lng = this.props.user.location.coordinates[0];
    if (this.state.aptNumber) data.aptNumber = this.state.aptNumber;
    // data.note = this.state.note;
    // if (this.state.note) data.note = this.state.note;
    axios({
      method: "patch",
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/orders/${this.state.pendingOrder._id}`,
      data: data,
      withCredentials: true,
    }).then((response) => {
      // // console.log(response);
      // if (!this.state.pendingOrder.today) {
      //   alert(
      //     "Your order was succesfully registered. Please wait for a confirmation SMS, where you will be soon prompted to pay."
      //   );
      //   this.setState({
      //     pendingOrder: response.data.data.order,
      //     submitLoading: false,
      //   });
      //   this.setState({ redirect: true });
      // } else {
      this.setState({
        pendingOrder: response.data.data.order,
        redirectToday: true,
        submitLoading: false,
      });
      // }
    });
    // if (this.state.delivery) {
    //   data.deliveryId = this.state.deliveryId;
    // } WILL HAVE TO BE DONE IN CHECKOUT SESSION
  };

  submitCheckout = () => {
    this.setState({ submitLoading: true });
    if (
      this.state.phone === null ||
      this.state.phone === "" ||
      this.state.phone === undefined
    ) {
      this.setState({ submitLoading: false });
      alert(
        "Please enter your phone number first so we can contact you in delivery."
      );

      return;
    } else if (
      (this.state.address === null || this.state.address === undefined) &&
      this.state.delivery
    ) {
      this.setState({ submitLoading: false });
      alert(
        "Please enter your address in the home page or in your profile page first! We don't allow you to update your address here as we need to ensure we can deliver the food to you on time based on your address. If this address is incorrect, you will unfortunately have to remake your order."
      );
      return;
    } else if (
      !this.state.warn &&
      this.state.delivery &&
      this.state.pendingOrder &&
      this.state.pendingOrder.chef &&
      this.state.pendingOrder.chef.cuisine.includes("Cakes") //if not selected and delivery order and chef contains cake
    ) {
      this.setState({ submitLoading: false });
      alert(
        "Please check the checkbox right above to acknowledge that you've read the disclaimer/note"
      );
      return;
    } else if (
      this.state.pendingOrder.selectedTime === null ||
      this.state.pendingOrder.selectedTime === undefined
    ) {
      this.setState({ submitLoading: false });
      alert(
        "Please select a time for which you want your order to be ready by"
      );
    } else {
      this.axiosUpdate(); //with fee, and currency
    }
  };

  updateWarn = async () => {
    // console.log("warnnn 1", this.state.warn);
    let warn = !this.state.warn;
    // console.log("warnnn 2", warn);
    await this.setState({ warn });
    // console.log("final warn", this.state.warn);
  };

  setQuoteLoading = () => {
    console.log("quote loading true");
    // this.setState({ deliveryFee: null });
  };

  render() {
    if (!this.state.loading) {
      let date = this.state.pendingOrder.deliveryDate;
      let delivery = `deliveryTimes${date}`;
      return (
        <section className="checkout_details">
          {this.state.redirect ? <Redirect to="/cart" /> : <div></div>}
          {this.state.redirectToday ? (
            <Redirect
              to={{
                pathname: `/pay/${this.state.pendingOrder._id}`,
                state: { today: true },
              }}
            />
          ) : (
            <div></div>
          )}
          <div className="container_homechef checkout_cont">
            <Helmet>
              <title>tizaar: Checkout</title>
              <meta
                name="description"
                content="Tickle your taste buds at tizaar's food bazaar and unconver hidden food delights right in your very neighborhood!"
              />
            </Helmet>{" "}
            <div className="left_checkout_container">
              {/* <div className="checkout_address">Delivery Address</div>
            <input type="text" className="address_input" placeholder="Street address, City"/> */}
              {/* <b style={{ color: "red", fontFamily: "landingFontTwo" }}>
              At this moment in time we only support pickup! Delivery services
              will be coming soon!{" "}
            </b> */}
              <div
                className="process_timeline_cont"
                style={{ marginTop: "50px", fontFamily: "landingFontTwo" }}
              >
                {this.state.pendingOrder.chef &&
                this.state.pendingOrder.chef.note ? (
                  <div style={{ marginBottom: "10px" }}>
                    <h1>
                      <b>Chef Note: </b>
                    </h1>
                    <br></br>
                    <p style={{ fontFamily: "landingFontTwo" }}>
                      {this.state.pendingOrder.chef.note}
                    </p>
                  </div>
                ) : null}
                {/*
                <h1>
                  <b>
                    {this.state.pendingOrder.today
                      ? "Delivery/Pickup Timeline (Today Orders): "
                      : "Delivery/Pickup Timeline: "}
                  </b>
                </h1>
                <div style={{ fontFamily: "landingFontTwo" }}>
                  {!this.state.pendingOrder.today ? (
                    <div style={{ padding: "15px", paddingTop: "30px" }}>
                      <div className="process_step">
                        <div className="icon_wrapper_checkout">
                          <i className="fas fa-check"></i>
                        </div>
                        <p className="process_description">
                          Confirm order at checkout
                        </p>
                      </div>
                      <div className="process_step">
                        <div className="icon_wrapper_checkout">
                          <div
                            className="process_icon image"
                            style={{
                              backgroundImage: `url(${process.env.PUBLIC_URL}/cart/order.svg)`,
                            }}
                          ></div>
                        </div>
                        <p className="process_description">
                          Wait for chef to confirm order
                        </p>
                      </div>
                      <div className="process_step">
                        <div className="icon_wrapper_checkout">
                          <i className="fas fa-credit-card"></i>
                        </div>
                        <p className="process_description refrigerate_caption">
                          Pay for your order via SMS'd link
                        </p>
                      </div>
                      <div className="process_step">
                        <div className="icon_wrapper_checkout">
                          <div
                            className="process_icon image"
                            style={{
                              backgroundImage: `url(${process.env.PUBLIC_URL}/cart/eat.svg)`,
                            }}
                          ></div>
                        </div>
                        <p className="process_description refrigerate_caption">
                          Order is prepared
                        </p>
                      </div>
                      <div className="process_step">
                        <div className="icon_wrapper_checkout">
                          <div
                            className="process_icon image"
                            style={{
                              backgroundImage: `url(${process.env.PUBLIC_URL}/cart/deliver.svg)`,
                            }}
                          ></div>
                        </div>
                        <p className="process_description">
                          SMS sent prior to delivery or pickup!{" "}
                          <b>
                            For pickup orders, the pickup address will be
                            provided in this SMS and via email receipt.
                          </b>
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div style={{ padding: "15px", paddingTop: "30px" }}>
                      <div className="process_step">
                        <div className="icon_wrapper_checkout">
                          <i className="fas fa-credit-card"></i>
                        </div>
                        <p className="process_description">
                          Review order and pay
                        </p>
                      </div>
                      <div className="process_step">
                        <div className="icon_wrapper_checkout">
                          <div
                            className="process_icon image"
                            style={{
                              backgroundImage: `url(${process.env.PUBLIC_URL}/cart/order.svg)`,
                            }}
                          ></div>
                        </div>
                        <p className="process_description">
                          Chef will confirm order
                        </p>
                      </div>
                      <div className="process_step">
                        <div className="icon_wrapper_checkout">
                          <i className="fas fa-check"></i>
                        </div>
                        <p className="process_description refrigerate_caption">
                          You will be notified upon order completion
                        </p>
                      </div>
                    </div>
                  )}
                </div> */}
              </div>
              <h1
                style={{ fontFamily: "landingFontTwo", marginBottom: "10px" }}
              >
                Please select method of delivery
              </h1>
              <div className="select_deliv flex_cont">
                {console.log(
                  "PICKUP",
                  this.state.pendingOrder.chef.pickup,
                  this.state.delivery
                )}
                {!this.state.pendingOrder.chef.pickup ? (
                  <div></div>
                ) : (
                  <div
                    className="mode_1"
                    onClick={() => this.setState({ delivery: false })}
                  >
                    <input
                      type="radio"
                      className="radio_button_time"
                      // name="deliv_mode"
                      // id="deliv_mode"
                      value={false}
                      // defaultChecked
                      checked={!this.state.delivery}
                    />
                    <label
                      htmlFor={"pickup_mode"}
                      style={{ marginLeft: "10px" }}
                      className="label"
                    >
                      Pickup
                      {/*TIME DISPLAYED TO CUSTOMER*/}
                    </label>
                  </div>
                )}
                <div
                  className="mode_1 mode_2"
                  onClick={() => this.setState({ delivery: true })}
                >
                  <input
                    type="radio"
                    className="radio_button_time"
                    // name="deliv_mode"
                    // id="deliv_mode"
                    value={true}
                    checked={this.state.delivery}
                  />
                  <label
                    htmlFor={"deliv_mode"}
                    style={{ marginLeft: "10px" }}
                    className="label"
                  >
                    Delivery
                  </label>
                </div>
              </div>
              {this.state.delivery ? (
                <div className="delivery_address_checkout">
                  {console.log(
                    "checkout address",
                    this.state.pendingOrder.address,
                    this.state.pendingOrder
                  )}
                  <h1>Delivery Address</h1>
                  <div className="checkout_address">
                    <LocationInput
                      updateLocation={this.props.updateUserLocation}
                      user={this.props.user}
                      date={new Date()}
                      homechef={true}
                      valueC={this.state.address}
                      setLocation={this.setLocation}
                      setLoading={this.setQuoteLoading}
                      // placeholder={this.state.pendingOrder.address}
                    />
                  </div>
                  {/* <input
                    type="text"
                    placeholder="Street Address, City, State"
                    className="phone_input_c"
                    value={
                      this.state.pendingOrder.address
                        ? this.state.pendingOrder.address
                        : ""
                    }
                    readOnly="readonly"
                    // onChange={(e) => this.setState({ address: e.target.value })} Shouldn't be allowed to change address Make note because needs to be deliverable on time!
                  /> */}
                  {/* <b style={{ color: "red", fontFamily: "landingFontTwo" }}>
                    To update the address, if need be, please go to the
                    homepage/profile page, update your address, and then
                    recreate the order! We need to ensure it's close to you to
                    get your order on time!
                  </b> */}
                </div>
              ) : (
                <div className="delivery_address_checkout">
                  <h1>Chef Pickup Address</h1>
                  <div className="phone_input_c">
                    {this.state.pendingOrder.chef.address}
                  </div>
                </div>
              )}
              {/* ADD APT NOTE */}
              {/* {this.state.chefNote ? (
                <div className="delivery_address_checkout">
                  <h1>Assortment Types/Flavors</h1>
                  <textarea
                    cols="30"
                    rows="3"
                    type="text"
                    className="phone_input_c"
                    value={this.state.chefNote}
                    onChange={(e) => {
                      this.setState({ chefNote: e.target.value });
                    }}
                    // onChange={(e) => this.setState({ address: e.target.value })} Shouldn't be allowed to change address Make note because needs to be deliverable on time!
                  />{" "}
                </div>
              ) : (
                <div></div>
              )} */}
              <div className="delivery_address_checkout">
                <h1>Apartment Number if Applicable</h1>
                <input
                  placeholder="i.e 5B"
                  className="phone_input_c"
                  value={this.state.aptNumber}
                  onChange={(e) => {
                    this.setState({ aptNumber: e.target.value });
                  }}
                  // onChange={(e) => this.setState({ address: e.target.value })} Shouldn't be allowed to change address Make note because needs to be deliverable on time!
                />
              </div>
              <div className="delivery_functionality">
                <div>
                  {/* {// console.log("ORDER DATES____")}
                    {// console.log(this.state.orderDates)} */}
                  {/* <div className="delivery_for_date">Deliveries for {date}</div> */}
                  <div className="delivery_times_for_date">
                    <h1>
                      Delivery Times Available for {date},{"  "}
                      {this.state.pendingOrder.actualDate}
                    </h1>
                    <div className="radio_time_selector">
                      {console.log(this.state.pendingOrder)}
                      {this.state.pendingOrder.chef[delivery].map((time) => {
                        if (this.validateTime(time)) {
                          return (
                            <div key={time}>
                              <input
                                type="radio"
                                className="radio_button_time"
                                name={`radio_${date}`}
                                id={time}
                                value={time}
                                onClick={() => this.updateSelectedTime(time)} //CHEFS need to finish by that hour time! Make sure to always give hour + 1
                              />
                              <label
                                htmlFor={time}
                                style={{ marginLeft: "10px" }}
                              >
                                {this.state.delivery
                                  ? this.convertTime(time + 1)
                                  : this.convertTime(time)}{" "}
                                {/* Depends on whether delivery or not */}
                                {/*TIME DISPLAYED TO CUSTOMER*/}
                              </label>
                            </div>
                          );
                        } else return;
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="phone_number_checkout">
                <h1>Phone Number</h1>
                <div className="phone_input_div">
                  <span className="phone_input_span">
                    <b> +63</b>
                  </span>
                  <input
                    type="text"
                    placeholder="Phone Number"
                    className="phone_input_c phone_input_comp"
                    value={this.state.phone ? this.state.phone : ""}
                    maxLength={10}
                    onChange={(e) => {
                      this.setState({ phone: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className="optional_note">
                <h1 style={{ fontFamily: "landingFontTwo", padding: "15px" }}>
                  Optional Note to Chef
                </h1>
                <textarea
                  name="optional_note"
                  value={this.state.note}
                  id=""
                  onChange={(e) => {
                    this.setState({ note: e.target.value });
                  }}
                  style={{ width: "100%", minHeight: "100px" }}
                  placeholder="If you want to remove certain things from your dish, like nuts, or have anything else you want the chef to know about please put it here!"
                ></textarea>
              </div>
              {/* <div className="select_deliv flex_cont">
                <div className="mode_1 payment_type_radios">
                  {!this.state.delivery ? (
                    <div className="wrapper_pt">
                      <input
                        type="radio"
                        className="radio_button_time"
                        name="pt"
                        id="cod_mode"
                        value={false}
                        onClick={() => this.setState({ digital: false })}
                        defaultChecked
                      />
                      <label
                        htmlFor={"cod_mode"}
                        style={{ marginLeft: "10px" }}
                        className="label"
                      >
                        Cash on Delivery (COD)
                      </label>
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <div className="wrapper_pt">
                    <input
                      type="radio"
                      className="radio_button_time"
                      name="pt"
                      id="digital_mode"
                      value={false}
                      onClick={() => this.setState({ digital: true })}
                    />
                    <label
                      htmlFor={"digital_mode"}
                      style={{ marginLeft: "10px" }}
                      className="label"
                    >
                      Pay Digitally (GCash, GrabPay, Credit/Debit, etc.. )
                      
                    </label>
                  </div>
                </div>
              </div> */}
              {/* INTEGRATE PAYMONGO HERE! CAN you load it into site or does it redirect?  */}
              {this.state.digital ? (
                <div className="payment_detail_checkout">
                  <h1>Payment Details</h1>
                  <div className="main_card_info">
                    <div className="card_detail">
                      <p>CARD DETAILS</p>
                      <input
                        type="text"
                        className="card_number_fill phone_input_c"
                        placeholder="1234 1234 1234"
                      />
                    </div>
                    <div className="card_detail_cvv">
                      <p>CVV</p>
                      <input
                        type="text"
                        className="card_cvv_fill phone_input_c"
                        placeholder="1234"
                      />
                    </div>
                  </div>
                  <div className="card_detail_cvv card_detail_expiration">
                    <p>EXPIRATION DATE</p>
                    <input
                      type="text"
                      className="card_cvv_fill phone_input_c"
                      placeholder="07/24"
                    />
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
            {console.log(typeof this.state.deliveryFee)}
            {
              // !this.state.quoteLoading &&
              // this.state.deliveryFee &&
              !isNaN(this.state.deliveryFee) ? (
                <div className="right_checkout_container">
                  <h1>My Order</h1>
                  {this.state.pendingOrder.dishList.map((dish, i) => {
                    return (
                      <div className="dish_item_list_checkout" key={i}>
                        <div
                          className="dish_photo_checkout image"
                          style={{ backgroundImage: `url(${dish.dish.photo})` }}
                        ></div>
                        <div className="dish_data_checkout">
                          <h1 className="dish_name_checkout">
                            {dish.quantity}*{dish.dish.name}
                          </h1>
                          <p className="dish_deliveryDate_checkout">
                            {this.dateMap[date]}
                          </p>
                          <div>
                            {dish.note ? (
                              <p style={{ fontFamily: "landingFontTwo" }}>
                                {dish.note}
                              </p>
                            ) : null}
                          </div>
                        </div>
                        <h1
                          className="extra-header-checkout"
                          style={{
                            marginLeft: "20px",
                            fontFamily: "landingFontTwo",
                          }}
                        >
                          <b>{dish.dish.price * dish.quantity}P</b>
                        </h1>
                      </div>
                    );
                  })}
                  {console.log("FEE;", this.state.deliveryFee)}
                  {this.state.delivery ? (
                    <div className="service_fee delivery_fee">
                      {this.state.deliveryFee ? (
                        <p>
                          EST. DELIVERY FEE:{" "}
                          {Math.round(1.031 * this.state.deliveryFee)}
                        </p>
                      ) : (
                        <p>DELIVERY FEE LOADING...</p>
                      )}
                      {/* <p className="note">*Not included in total price below</p> */}
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <div className="service_fee">
                    <p>
                      SERVICE FEE: <br></br> <b>5P</b> (gcash/grab_Pay){" "}
                      <br></br> <b>15P</b> (visa/mastercard)
                    </p>
                  </div>
                  <div
                    className="total_checkout flex_between"
                    style={{ marginTop: "50px", fontFamily: "landingFontTwo" }}
                  >
                    <h1>
                      <b>TOTAL:</b>
                    </h1>
                    <h1>
                      <b>
                        {this.calcTotalPrice() + 5}P -{" "}
                        {this.calcTotalPrice() + 15}P
                      </b>
                    </h1>
                  </div>
                  {this.state.delivery &&
                  this.state.pendingOrder &&
                  this.state.pendingOrder.chef &&
                  this.state.pendingOrder.chef.cuisine.includes("Cakes") ? (
                    <div className="deliv_warning">
                      <input
                        type="checkbox"
                        id={"warning"}
                        name={"warning"}
                        className="deliv_warning_input"
                        onClick={this.updateWarn}
                      />
                      <label
                        htmlFor={"warning"}
                        className="deliv_warning_label"
                      >
                        For orders like this we strongly recommend pickup. By
                        clicking this box you understand that neither tizaar nor
                        the chefs are liable for any damages to the food during
                        delivery, despite chefs doing their best to package
                        nicely.
                      </label>
                    </div>
                  ) : null}
                  <div className="checkout_btn" onClick={this.submitCheckout}>
                    {this.state.submitLoading ? (
                      <div>
                        Loading...{" "}
                        <Loader
                          type="Puff"
                          color="#FFFFFF"
                          height={20}
                          width={20}
                          timeout={3000} //3 secs
                        />
                      </div>
                    ) : true ? ( //this.state.pendingOrder.today
                      "PROCEED TO CHECKOUT"
                    ) : (
                      "CONFIRM ORDER"
                    )}
                  </div>
                </div>
              ) : (
                <div className="fullheight gray_b_c">
                  <div
                    className="chef_float float_check"
                    style={{
                      backgroundImage: `url(${process.env.PUBLIC_URL}/landing/chef.png)`,
                    }}
                  ></div>
                </div>
              )
            }
          </div>
        </section>
      );
    } else {
      return (
        <div className="fullheight">
          <div
            className="chef_float"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/landing/chef.png)`,
            }}
          ></div>
        </div>
      );
    }
  }
}

export default checkout;
