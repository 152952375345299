import React from "react";
import scriptLoader from "react-async-script-loader";
import { Redirect, NavLink, withRouter } from "react-router-dom";
// import Geocode from "react-geocode";
import PlacesAutocomplete, {
  geocodeByPlaceId,
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import "./locationInput.css";
import Store from "../Store";
import ReactDOM from "react-dom";
import axios from "axios";

// Geocode.setApiKey("AIzaSyCDTCEbBYYoythM-3jiOB_iwk0fmVb3krc");
// Geocode.setLanguage("en");
// Geocode.setRegion("ph");
// Geocode.setLocationType("ROOFTOP");

const searchOptions = {
  componentRestrictions: { country: ["ph"] },
  // types: ["postal_code"], //might have to convert to cities
};

class LocationInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: this.props.valueC
        ? this.props.valueC
        : this.props.user && this.props.user.address
        ? this.props.user.address
        : "",
      coordinates: {},
      updateAddress: !(
        (Store.getLat() &&
          Store.getLng()) /*&& Store.getAddress() TODO: ADD BACK*/ ||
        (this.props.user &&
          this.props.user.location &&
          this.props.user.location.coordinates)
      ),
      redirect: false,
      alert: null,
    };
    this.element = React.createRef(); //this.state.updateAddress
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
    if (this.input && this.input.current) {
      this.element.current.addEventListener("keypress", this.handleKeyPress);
    }
    // this.getLatLngByZipcode("1267");
  }

  componentDidUpdate() {
    if (this.input && this.input.current)
      this.element.current.addEventListener("keypress", this.handleKeyPress);
  }

  componentWillUnmount() {
    if (this.input && this.input.current)
      this.element.current.removeEventLitsener("keypress", this.handleKeyPress);
  }

  handleKeyPress(event) {
    // [Enter] should not submit the form when choosing an address.
    // console.log(this.state);
    if (event.keyCode === 13) {
      event.preventDefault();
      let self = this;
      this.setState({ alert: "Please select an address from the dropdown" });
      setTimeout(function () {
        self.setState({ alert: null });
      }, 3000);
      return;
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.date != this.props.date) {
      this.setState({ redirect: false });
    }
  }

  // getLatLngByZipcode(zipcode) {
  //   let lat,
  //     lng = null;

  //   axios({
  //     method: "get",
  //     url: `http://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyCDTCEbBYYoythM-3jiOB_iwk0fmVb3krc&components=postal_code:${zipcode}&countryCode={ph}`,
  //     withCredentials: true,
  //   }).then((result) => {
  //     // console.log("zip code RESULT", result);
  //   });
  //   // Geocode.fromAddress(`${zipcode}, PH`).then(
  //   //   (response) => {
  //   //     // console.log(response.results[0]);
  //   //     const { lat, lng } = response.results[0].geometry.location;
  //   //     alert(`${lat}, ${lng}`);
  //   //   },
  //   //   (error) => {
  //   //     alert(error);
  //   //     // console.log(error);
  //   //   }
  //   // );
  //   // return [lat, lng];
  // }

  handleChange = (address) => {
    this.setState({ address });
  };

  handleSelect = (address) => {
    if (this.props.setLoading) this.props.setLoading();
    geocodeByAddress(address)
      .then(async (results) => {
        // console.log(results[0], results[0].geometry.location.lat());
        let latLng = {
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        };
        // console.log("LATLNG", latLng, address);
        Store.setLat(latLng.lat);
        Store.setLng(latLng.lng);
        // console.log("selected address", address);
        Store.setAddress(address);

        if (this.props.setLocation) {
          this.props.setLocation(
            address,
            results[0].geometry.location.lat(),
            results[0].geometry.location.lng()
          );
        }
        if (this.props.user)
          await this.props.updateLocation(
            Object.values(latLng).reverse(),
            address
          );
        !this.props.profile && !this.props.homechef
          ? await this.setState({
              address,
              coordinates: latLng,
              redirect: true,
            })
          : await this.setState({ address, coordinates: latLng });
        // this.props.history.push("/bazaar");
        // return <Redirect to="/bazaar" />; //worked with NavLink try this. Try putting this in render. I.e update state then conditional
        //might have to call props here to update user with coordinates and address to home and then to parent render --> this is on completion in any case.
        //Call Redirect here to go to bazaar
      })
      .catch((error) => {
        alert(
          "There was an error in collecting your address! Apologies for the inconvenience."
        ); //allow them to visit the bazaar in any case? Call the protected route TOO!
        console.log(error);
      });
  };

  render() {
    if (this.state.redirect) return <Redirect to="/bazaar" />;
    return this.state.updateAddress || this.props.homechef ? (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        searchOptions={searchOptions}
      >
        {/* A function that is being called --> nested child function */}
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div
            className={
              !this.props.homechef ? "location-btn" : "homechef-location-btn"
            }
          >
            {this.state.alert ? (
              <div className={"error_alert"} style={{ zIndex: "10000000" }}>
                {this.state.alert}
              </div>
            ) : (
              <div></div>
            )}
            <div className="flex_cont">
              <i className="fas fa-map-marker-alt"></i>
              <input
                {...getInputProps({
                  placeholder: !this.props.homechef
                    ? "Enter your address to explore the bazaar ->"
                    : "Please enter your address",
                  className: !this.props.homechef
                    ? "location-search-input"
                    : "location-search-input-homechef",
                })}
                value={this.state.address}
                ref={this.element}
                // onSubmit={(e) => e.preventDefault()}
                onKeyDown={this.handleKeyPress}
              />
            </div>
            <div
              className={
                !this.props.homechef
                  ? "autocomplete-dropdown-container"
                  : "autocomplete-dropdown-container-homechef"
              }
            >
              {loading && <div className="loading_loc">Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? !this.props.homechef
                    ? "suggestion-item--active"
                    : "suggestion-item--active-homechef"
                  : !this.props.homechef
                  ? "suggestion-item"
                  : "suggestion-item-homechef";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: "#fafafa", cursor: "pointer" }
                  : { backgroundColor: "#ffffff", cursor: "pointer" };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    ) : (
      //to reduce googleAPI requests.
      <div
        className={
          !this.props.profile
            ? "location-btn flex_cont"
            : "location-btn location-btn-profile flex_cont"
        }
      >
        <NavLink
          to="/bazaar"
          className={
            this.props.profile
              ? "hover_explore no_transform"
              : "hover_explore no_translate"
          }
        >
          Explore the Bazaar &#10140;
        </NavLink>
        <div
          className="update_location"
          onClick={() => this.setState({ updateAddress: true })}
        >
          <div className="flex_cont">
            <p>
              Update <br />
              Location
            </p>
            <i className="fas fa-map-marker-alt" style={{ color: "black" }}></i>
          </div>
        </div>
      </div>
    );
  }
}

export default LocationInput;
