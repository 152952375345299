import React, { Component } from "react";
import "./footnote.css";

export class footnote extends Component {
  render() {
    return (
      <section className="footer">
        <div className="tizaar_description col-4">
          <h1>tizaar</h1>
          <p className="tizaar_description">
            Tizaar aims to disrupt the F&B industry. We believe everyone should
            have access to affordable delicious meals. We wish to build a
            positive service-oriented community. Interested in working for
            tizaar?{" "}
            <b>
              See{" "}
              <a
                target="_blank"
                href="https://docs.google.com/document/d/1OWZRXe3qEOFm6lsLJAHSSZPo9oo6GwAFaC-3m1zVxZk/edit?usp=sharing"
                className="careers_link"
              >
                careers...
              </a>
            </b>
          </p>
          <div className="social_icons_footnote">
            {/* <a
              href="https://facebook.com/tizaar"
              className="fb_tizaar social_icon"
            >
              <i className="fab fa-facebook"></i>
            </a> */}
            <a
              href="https://www.instagram.com/tizaar_ph/?hl=en"
              className="insta_tizaar social_icon"
            >
              <i className="fab fa-instagram"></i>
            </a>
          </div>
          <p className="copyright">© tizaar.inc</p>
        </div>
        <div className="contact_details_footnote">
          <p>
            If you have any questions, feel free to contact us at{" "}
            <span className="email" style={{ color: "#ff8c37" }}>
              mahtanir@wharton.upenn.edu
            </span>
          </p>
          <div className="phone_container_footnote">
            <div>
              <i className="fas fa-phone"></i>
              <div className="phone_number_footnote">
                <span>(+63) </span> 995-252-1699
              </div>
            </div>
            <div className="time_hours">
              <div>Monday - Sunday</div>
              <div>10am - 10pm</div>
            </div>
          </div>
          <p className="note_footnote">
            Given our small team at the moment we may not be able to answer all
            calls. Please send an email if unreachable.
          </p>
        </div>
      </section>
    );
  }
}

export default footnote;
