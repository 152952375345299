import React, { Component } from "react";
import axios from "axios";
import "./miniCart.css";
import CartListing from "./cartListing";
import { NavLink } from "react-router-dom";

export class miniCart extends Component {
  state = {
    chef: this.props.chef,
    pendingOrders: null,
  };

  componentDidMount() {
    // console.log("FLOOOODD load");
    this.getCartOrders();
  }

  componentDidUpdate(prevProps) {
    // console.log("FLOOOODD update");
    if (prevProps.pendingOrderDate !== this.props.pendingOrderDate) {
      this.getCartOrders();
    }
  }

  getCartOrders = () => {
    // console.log("FLOOOODD CART");

    // console.log(this.props.chef.id, this.props.chef._id);
    axios({
      method: "get",
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/orders/userchef?chef=${this.props.chef.id}`,
      withCredentials: true,
    }).then((response) => {
      this.setState({ pendingOrders: response.data.data.orders });
    });
  };

  updateCartOrder = (id, dishId, quantity, index) => {
    // console.log("FLOOOODD update cart");
    // if (quantity === 0) {
    //   //delete was slow so shortcut?
    //   let pendingOrders = [...this.state.pendingOrders];
    //   pendingOrders.splice(index, 1);
    //   this.setState({ pendingOrders });
    // }
    axios({
      method: "patch",
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/orders/quantity/${id}`,
      data: { dishId: dishId, quantity: quantity },
      withCredentials: true,
    }).then((response) => {
      let pendingOrders = [...this.state.pendingOrders];
      if (response.data.status !== "deleted") {
        pendingOrders[index] = response.data.data;
        // console.log(pendingOrders);
      } else {
        // console.log("SPLICE");
        pendingOrders.splice(index, 1);
      }
      this.props.notify();
      this.setState({ pendingOrders });
    });
  };

  returnEmpty = () => {
    return (
      <div>
        <img
          src="https://tizaar-foodbazaar.s3-ap-southeast-1.amazonaws.com/dish.svg"
          alt=""
          className="icon"
        />
        <p className="wrapword">
          There are no dishes in your cart for this chef at the moment
        </p>
      </div>
    );
  };
  render() {
    if (!this.state.pendingOrders) {
      return (
        <div style={{ maxWidth: "21%" }}>
          <section className="mini_cart_section">
            <img
              src="https://tizaar-foodbazaar.s3-ap-southeast-1.amazonaws.com/dish.svg"
              alt=""
              className="icon"
            />
            <p className="no_cart_empt">
              There are no dishes in your cart for this chef at the moment
            </p>
          </section>
        </div>
      );
    } else {
      return (
        <div style={{ maxWidth: "21%" }}>
          <section className="mini_cart_section scroll-y">
            {/* <h1 className="order_chef_name">
            Your orders from {this.props.order.chef.name}
          </h1>
          <h1 className="order_cart_date">{this.props.order.actualDate}</h1> */}
            {Array.from(this.state.pendingOrders).length === 0 ? (
              this.returnEmpty()
            ) : (
              <div></div>
            )}
            <h1 className="order_chef_name">
              Your orders with Chef {this.props.chef.name}
            </h1>
            <div style={{ textAlign: "right" }}>
              <NavLink to="/cart" className="cart_navlink">
                See Cart
              </NavLink>
            </div>
            {Array.from(this.state.pendingOrders).map((order, i) => {
              return (
                <div style={{ marginTop: "20px" }} key={i}>
                  <h1 className="order_cart_date">
                    Order for <b>{order.actualDate}</b>
                  </h1>
                  {Array.from(order.dishList).map((dish, io) => {
                    return (
                      <CartListing
                        key={order._id}
                        order={order}
                        indexOrder={i}
                        indexDish={io}
                        dish={dish}
                        updateCartOrder={this.updateCartOrder}
                      />
                    );
                  })}
                </div>
              );
            })}
          </section>
          <div className="bottom_fix_cart">
            <div className="flex_cont extend">
              <div className="pair">
                <i className="fas fa-shopping-cart cart_fix"></i>
                {this.state.pendingOrders.length}
              </div>

              <NavLink to="/cart">
                <p>
                  <b>View Cart</b>
                </p>
              </NavLink>
              <div className="pair">
                <p>Chef {this.state.chef.name}</p>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default miniCart;
