// import(/* webpackPreload: true */ "../fonts/Anton-Regular.woff");
import { BrowserRouter, Redirect, Route } from "react-router-dom";
import React, { Component } from "react";
import logo from "../logo.svg";
import "./App.css";
import Navbar from "./navbar/navbar.jsx";
import Home from "./home/landing.jsx";
import Bazaar from "./bazaar/bazaar.jsx";
import Dashboard from "./dashboard/dashboard.jsx";
import Homechef from "./homechef/homechef.jsx";
import Menu from "./Menu/menu";
import Receipt from "./receipt/receipt";
import MyMenu from "./MyMenu/MyMenu";
import Cart from "./cart/cart";
import FinalCheckout from "./cart/finalCheckout";
import Checkout from "./cart/checkout";
import Profile from "./profile/profile";
import Login from "./login/login";
import EmailInput from "./login/emailInput";
import ResetPassword from "./login/resetPassword";
import Signup from "./login/signup";
import Footnote from "./footnote/footnote";
import RatingsPopup from "./ratings/ratingsPopup";
import Store from "./Store";
import Analytics from "./analytics/analytics";
// import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";

export class App extends Component {
  state = {
    user: null,
    loginCheck: false,
    activeLink: "",
    historyLink: null,
  };

  //put LOADING all the way here. Then display none?

  setHistoryLink = (link) => {
    this.setState({ historyLink: link });
  };

  setActiveLink = (link) => {
    this.setState({ activeLink: link });
  };

  componentDidMount() {
    //loginCheck and retrive user here for protected routes. If loginCheck is false then keep reloading the page.
    //AXIOS GET CALL to get user! isLoggedIn!
    // console.log("App js 1", process.env, process.env.REACT_APP_URL);
    axios({
      method: "get",
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/users/loggedin`,
      withCredentials: true,
    })
      .then((response) => {
        // // console.log(response.data.data.user, response.data);
        if (response.data.loggedIn) {
          this.setState({ user: response.data.data.user, loginCheck: true });
          if (
            !response.data.data.user.address ||
            !response.data.data.user.location
          ) {
            /*TODO: REMOVE LATER*/
            // Store.setLat(14.5352021);
            // Store.setLng(121.0308343);
            // // Store.setAddress("");
            // this.updateUserLocation([121.0308343, 14.5352021], "");
            /*END OF TODO*/
          }
        } else {
          // console.log("not logged in");
          /*TODO: REMOVE LATER*/
          // Store.setLat(14.5352021);
          // Store.setLng(121.0308343);
          // // Store.setAddress("");
          // this.updateUserLocation(
          //   [121.0308343, 14.5352021],
          //   "123 Chestnut, Dasma, Makati, Demo Address"
          // );
          /*END OF TODO*/
          this.setState({ loginCheck: true });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  userLocationAfterLogin = () => {
    // console.log(
    //   Store.getLat(),
    //   Store.getLng(),
    //   Store.getAddress(),
    //   this.state.user ? this.state.user.location : this.state.user,
    //   this.state.user ? this.state.user.address : "n/a address"
    // );
    if (
      this.state.user &&
      !this.state.user.location &&
      Store.getLat() &&
      Store.getLng()
    ) {
      this.updateUserLocation(
        [Store.getLng(), Store.getLat()],
        Store.getAddress()
      );
    }
  };

  componentDidUpdate = () => {
    // console.log(this.state);
    // console.log("@ update app.js");
    this.userLocationAfterLogin();
  };

  setUser = (user) => {
    this.setState({ user });
    // console.log("At set User", this.state.user);
  };

  updateUserLocation = async (coordinates, address) => {
    let user = { ...this.state.user };
    user.location = { coordinates, type: "Point" };
    user.address = address;
    // console.log("Address", address);
    let response = await axios({
      method: "patch",
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/users/me`,
      data: { location: user.location, address: address },
      withCredentials: true,
    })
      .then((response) => {
        this.setState({ user });
        // console.log("USER WITH ADDRESS, ", user.address);
      })
      .catch((err) => console.log(err));
  };

  setLoading = (bool) => {
    if (bool) {
      setTimeout(() => this.setState({ loginCheck: true }), 2000);
    } else {
      this.setState({ loginCheck: bool });
    }
  };

  // setLoading = () => {
  //   this.setState({ loginCheck: false });
  //   setTimeout(() => this.setState({ loginCheck: true }), 2000);
  // };

  home = () => {
    return (
      <div>
        {/* <Navbar user={this.state.user} home={true} />{" "} */}
        <Home
          user={this.state.user}
          updateUserLocation={this.updateUserLocation}
        />
      </div>
    );
  };

  updateUser = async (user) => {
    await this.setState({ user });
  };

  render() {
    // console.log("RENDER AGAIN", this.state.loginCheck, this.state.user);
    // <Router>
    if (this.state.loginCheck) {
      if (this.state.user /*|| true*/) {
        //remove this true later!
        return (
          <BrowserRouter>
            <div>
              <Route
                path={[
                  "/",
                  "/bazaar",
                  "/dashboard",
                  "/vendor",
                  "/my-menu",
                  "/cart",
                  "/profile",
                  "/analytics",
                ]}
                exact
                component={() => <Navbar user={this.state.user} />}
              />
              <Route
                path={["/menu", "/checkout", "/track"]}
                component={() => <Navbar user={this.state.user} />}
              />
              {/* <Navbar /> */}
              <Route path="/" exact component={this.home} />
              <Route
                path="/login"
                activeClassName="active_link"
                component={() => {
                  if (!this.state.historyLink) {
                    return <Redirect to="/" />;
                  } else {
                    // window.location.reload(); //CHECK ruins saved order

                    return <Redirect to={this.state.historyLink} />;
                  }
                }}
              />
              <Route
                path="/forgotPasswordStageOne"
                activeClassName="active_link"
                component={() =>
                  this.state.user ? <Redirect to="/" /> : <EmailInput />
                }
              />
              <Route
                path="/signup"
                component={() => {
                  if (!this.state.historyLink) {
                    return <Redirect to="/" />;
                  } else {
                    return <Redirect to={this.state.historyLink} />;
                  }
                }}
              />
              <Route
                path="/forgotPassword/:tokenId"
                render={(matchProps) => {
                  return this.state.user ? (
                    <Redirect to="/" />
                  ) : (
                    <ResetPassword
                      {...matchProps}
                      {...this.props}
                      setUser={this.setUser}
                    />
                  );
                }}
              />
              <Route //NO POPULATE QUERY! Create separate queries!
                path="/bazaar"
                component={() =>
                  this.state.user.location ? (
                    <Bazaar user={this.state.user} />
                  ) : (
                    <div>
                      {/*TRY*/}
                      <Redirect to="/" />
                      {alert("Please enter an address first")}
                    </div>
                  )
                }
              />
              <Route
                path="/dashboard"
                component={() =>
                  this.state.user ? (
                    <Dashboard user={this.state.user} setUser={this.setUser} />
                  ) : (
                    <Redirect to={{ pathname: "/login" }} />
                  )
                }
              />
              <Route
                path="/rating/:id"
                render={(matchProps) => {
                  // console.log("at rating");
                  return this.state.user ? (
                    <RatingsPopup
                      {...matchProps}
                      {...this.props}
                      user={this.state.user}
                      setHistoryLink={this.setHistoryLink}
                    />
                  ) : (
                    <Redirect to={{ pathname: "/login" }} />
                  );
                }} //How to do both?
              />
              <Route
                path="/vendor"
                render={(matchProps) => {
                  return (
                    <Homechef
                      {...matchProps}
                      {...this.props}
                      setUser={this.setUser}
                      updateUserLocation={this.updateUserLocation}
                      user={this.state.user}
                      setHistoryLink={this.setHistoryLink}
                    />
                  );
                }} //How to do both?
              />
              <Route
                path="/menu/:chefId"
                render={(matchProps) => {
                  return this.state.user ? (
                    <Menu
                      {...matchProps}
                      {...this.props}
                      userId={this.state.user._id}
                      user={this.state.user}
                      fromLogin={this.state.historyLink ? true : false} //lol pm redundant
                      setHistoryLink={this.setHistoryLink}
                    />
                  ) : (
                    <Redirect to={{ pathname: "/login" }} />
                  );
                }} //How to do both?
              />
              <Route
                path="/my-menu"
                component={() =>
                  this.state.user ? (
                    <MyMenu
                      user={this.state.user}
                      updateUser={this.updateUser}
                    />
                  ) : (
                    <Redirect to={{ pathname: "/login" }} />
                  )
                }
              />
              {/* Pass in props the query parameters/body parameters and then  do a request with that. Get methods usually no body!*/}
              <Route
                path="/cart"
                component={() =>
                  this.state.user ? (
                    <Cart user={this.state.user} date={new Date()} />
                  ) : (
                    <Redirect to={{ pathname: "/login" }} />
                  )
                }
              />
              <Route
                path="/pay/:id"
                render={(matchProps) => {
                  return (
                    <FinalCheckout
                      {...matchProps}
                      {...this.props}
                      fromLogin={this.state.historyLink ? true : false}
                      user={this.state.user}
                      setHistoryLink={this.setHistoryLink}
                    />
                  );
                }}
              />
              <Route
                path="/checkout/:id"
                render={(matchProps) => {
                  return this.state.user ? (
                    <Checkout
                      {...matchProps}
                      {...this.props}
                      user={this.state.user}
                      setUser={this.setUser}
                      updateUserLocation={this.updateUserLocation}
                    />
                  ) : (
                    <Redirect to={{ pathname: "/login" }} />
                  );
                }} //How to do both?
              />
              <Route
                path="/profile"
                component={() =>
                  this.state.user ? (
                    <Profile
                      user={this.state.user}
                      setUser={this.updateUser}
                      updateUserLocation={this.updateUserLocation}
                    />
                  ) : (
                    <Redirect to={{ pathname: "/login" }} />
                  )
                }
              />
              <Route
                path="/track/:id"
                render={(matchProps) => {
                  return <Receipt {...matchProps} {...this.props} />;
                }} //How to do both?
              />
              <Route
                path="/analytics"
                component={() => <Analytics user={this.state.user} />}
              />
              <Footnote />
            </div>
          </BrowserRouter>
        );
      } else {
        return (
          <div>
            <BrowserRouter>
              <Route
                path="/pay/:id"
                render={(matchProps) => {
                  return (
                    <FinalCheckout
                      {...matchProps}
                      {...this.props}
                      user={null}
                      setHistoryLink={this.setHistoryLink}
                    />
                  );
                }}
              />
              <Route
                path={["/bazaar", "/vendor", "/", "/cart"]}
                exact
                component={() => <Navbar user={this.state.user} />}
              />
              <Route
                path="/menu"
                component={() => <Navbar user={this.state.user} />}
              />
              <Route path="/" exact component={this.home} />
              <Route //NO POPULATE QUERY! Create separate queries!
                path="/bazaar"
                component={() =>
                  Store.getLat() && Store.getLng() ? (
                    <Bazaar
                      user={this.state.user}
                      lat={Store.getLat()}
                      lng={Store.getLng()}
                    />
                  ) : (
                    <div>
                      {/*TRY*/}
                      <Redirect to="/" />
                      {alert("Please enter an address first")}
                    </div>
                  )
                }
              />
              <Route
                path="/menu/:chefId"
                render={(matchProps) => {
                  return (
                    <Menu
                      {...matchProps}
                      {...this.props}
                      userId={null}
                      user={null}
                      setHistoryLink={this.setHistoryLink}
                    />
                  );
                }} //How to do both?
              />
              <Route
                path="/vendor"
                render={(matchProps) => {
                  return (
                    <Homechef
                      {...matchProps}
                      {...this.props}
                      setHistoryLink={this.setHistoryLink}
                    />
                  );
                }} //How to do both?
              />
              <Route
                path={[
                  "/dashboard",
                  // "/vendor",
                  "/my-menu",
                  "/cart",
                  "/checkout",
                  "/profile",
                  "/analytics",
                ]}
              >
                <Redirect to="/login" />
              </Route>
              <Route
                path="/login"
                exact
                activeClassName="active_link"
                component={() => (
                  <Login setUser={this.setUser} setLoading={this.setLoading} />
                )}
              />
              <Route
                path="/forgotPasswordStageOne"
                activeClassName="active_link"
                component={() =>
                  this.state.user ? <Redirect to="/" /> : <EmailInput />
                }
              />
              <Route
                path="/forgotPassword/:tokenId"
                render={(matchProps) => {
                  return this.state.user ? (
                    <Redirect to="/" />
                  ) : (
                    <ResetPassword
                      {...matchProps}
                      {...this.props}
                      setUser={this.setUser}
                    />
                  );
                }}
              />
              <Route
                path="/signup"
                component={() =>
                  this.state.user ? (
                    <Redirect to="/" />
                  ) : (
                    <Signup
                      setUser={this.setUser}
                      setLoading={this.setLoading}
                    />
                  )
                }
              />
              <Route
                path="/rating/:id"
                render={(matchProps) => {
                  // console.log("at rating");
                  return (
                    <RatingsPopup
                      {...matchProps}
                      {...this.props}
                      user={this.state.user} //How to do both?
                      setHistoryLink={this.setHistoryLink}
                    />
                  );
                }}
              />
              <Route
                path={["/", "/bazaar", "/vendor", "/menu", "/cart"]}
                component={() => <Footnote user={null} />}
              />
              <Route
                path="/track/:id"
                render={(matchProps) => {
                  return (
                    <Receipt
                      {...matchProps}
                      {...this.props}
                      setHistoryLink={this.setHistoryLink}
                    />
                  );
                }} //How to do both?
              />
            </BrowserRouter>
          </div>
        );
      }
    } else {
      return (
        <div
          className="chef_float"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/landing/chef.png)`,
          }}
        ></div>
      );
    }
  }
}

export default App;
