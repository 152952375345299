import React, { Component } from "react";
import Rating from "@material-ui/lab/Rating";
import { NavLink, Redirect } from "react-router-dom";
import "./ratingsPopup.css";
import axios from "axios";

export class ratingsPopup extends Component {
  state = {
    order: null,
    ratings: [],
    overallRating: null,
    errMessage: null,
    complete: false,
    loading: true,
    redirect: false,
  };

  componentDidMount() {
    if (this.props.user) {
      this.props.setHistoryLink(null);
    }
    if (
      !this.props.match ||
      !this.props.match.params ||
      !this.props.match.params.id
    ) {
      //through landing vs email
      axios({
        method: "get",
        url: `${
          process.env.NODE_ENV === "production"
            ? process.env.REACT_APP_URL
            : "http://localhost:8080"
        }/api/v1/orders/ratingPopup`,
        withCredentials: true,
      })
        .then((response) => {
          if (response.data.status === "success" && response.data.data.order) {
            this.setState({
              order: response.data.data.order,
              ratings: new Array(response.data.data.order.dishList.length),
              loading: false,
            });
          } else if (!response.data.data.order) {
            // console.log("none available for rating");
          } else {
            this.setState({
              errMessage: `There was an error in getting the rating popup to display. Thank you for supporting our chef and if it doesn't trouble you feel free to respond to the order email with the rating`,
              loading: false,
            });
          }
        })
        .catch((err) => console.log(err));
    } else {
      //else we are through landing
      axios({
        method: "get",
        url: `${
          process.env.NODE_ENV === "production"
            ? process.env.REACT_APP_URL
            : "http://localhost:8080"
        }/api/v1/orders/${this.props.match.params.id}`,
        withCredentials: true,
      })
        .then((response) => {
          if (response.data.status === "success") {
            this.setState({ order: response.data.data.order, loading: false });
          } else {
            console.log(
              `There was an error in getting the rating popup to display. Thank you for supporting our chef and if it doesn't trouble you feel free to respond to the order email with the rating`
            );
            this.setState({ loading: false });
          }
        })
        .catch((err) => console.log(err));
    }
  }

  submitRatings = () => {
    this.setState({ complete: true });
    axios({
      method: "post",
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/orders/ratingPopup`,
      withCredentials: true,
      data: {
        ratings: this.state.ratings,
        overallRating: this.state.overallRating,
        orderId: this.state.order._id,
      },
    })
      .then((response) => {
        if (response.data.status === "success") {
          // console.log("success");
          // this.setState({ complete: true });
        } else {
          // console.log("HEREEE");
          alert(response.data.message);
          // this.setState({ complete: true });
        }
      })
      .catch((err) => console.log(err));
  };

  render() {
    if (!this.props.user && this.props.match && this.props.match.params) {
      this.props.setHistoryLink(`/rating/${this.props.match.params.id}`);
      return <Redirect to="/login" />;
    }
    // console.log("USER", this.props.user);
    let isProps =
      this.props.match && this.props.match.params && this.props.match.params.id;
    if (this.state.order && !this.state.loading && !this.state.complete) {
      //&& this.state.order.user == this.props.user.id should be g when cookies are there
      return (
        <div>
          <div className="main_popup">
            <div
              className={
                this.state.order && !this.state.complete
                  ? "submit_rating_popup"
                  : "display_none"
              }
              onClick={this.submitRatings}
            >
              SUBMIT RATINGS OR <b>CLOSE</b>
            </div>
            <div className="overall_rating_div">
              <h1 className={this.state.order ? "" : "display_none"}>
                How was your meal from chef {this.state.order.chef.name}?
              </h1>
              <Rating
                name="simple-controlled"
                value={this.state.overallRating}
                onChange={(event, newValue) => {
                  this.setState({ overallRating: newValue });
                }}
                className={this.state.order ? "" : "display_none"}
              />
            </div>
            <p className="note">
              You don't need to rate everything/anything! Just what you want!
              The more the merrier :)
            </p>
            {this.state.order ? (
              <div className="dish_ratings_p">
                {this.state.order.dishList.map((dish, index) => {
                  return (
                    <div className="dish_rating_cont">
                      <div className="dish_rating_cont_header">
                        <div
                          className="dish_rating_photo image"
                          style={{ backgroundImage: `url(${dish.dish.photo})` }}
                        ></div>
                        <h1>{dish.dish.name}</h1>
                      </div>
                      <Rating
                        name={`${index}`}
                        value={this.state.ratings[index] || 0}
                        onChange={(event, newValue) => {
                          let ratings = [...this.state.ratings];
                          ratings[index] = newValue;
                          // console.log(ratings[index]);
                          this.setState({ ratings: ratings });
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      );
    } else if (!this.state.loading && !this.state.complete) {
      //IF NOT THE PERSON XC i.e can just type any mongoId and rate that OR ORDER NO EXIST

      if (isProps) {
        return (
          <div className="nothing_msg">
            There was an error in retrieving the order for you to rate. Thank
            you regardless for spending the time to rate! We will fix this ASAP!
          </div>
        );
      } else return null;
    } else if (isProps && this.state.complete) {
      return (
        <div>
          <p className="note">
            Thank you for rating! This will help the chefs on the platform
            monitor their quality and ultimately provide you with a better
            experience!{" "}
          </p>
          <NavLink to="/bazaar">
            <div className="submit_rating_popup_f" style={{ display: "block" }}>
              Explore the Bazaar
            </div>
          </NavLink>
        </div>
      );
    } else if (this.state.loading && isProps) {
      return (
        <div
          className="chef_float"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/landing/chef.png)`,
          }}
        ></div>
      );
    } else return null;
  }
}

export default ratingsPopup;
