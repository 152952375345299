import React, { Component } from "react";
import "./FoodMenuItem.css";
import axios from "axios";
import DateLogic from "../../util/dateFunctions";
import Rating from "@material-ui/lab/Rating";
import Store from "../Store";
import { Redirect } from "react-router-dom";

const monthMap = {
  0: "Jan",
  1: "Feb",
  2: "Mar",
  3: "Apr",
  4: "May",
  5: "Jun",
  6: "Jul",
  7: "Aug",
  8: "Sep",
  9: "Oct",
  10: "Nov",
  11: "Dec",
};

const dateIndexMapRe = {
  0: "Sun",
  1: "Mon",
  2: "Tue",
  3: "Wed",
  4: "Thu",
  5: "Fri",
  6: "Sat",
};

const dateMap = {
  Sun: 0,
  Mon: 1,
  Tue: 2,
  Wed: 3,
  Thu: 4,
  Fri: 5,
  Sat: 6,
};

export class FoodMenuItem extends Component {
  state = {
    cartLoading: false,
    todayClicked: true,
    photo: this.props.photo,
    pendingOrders: this.props.pendingOrders,
    description: this.props.description,
    currentDish: {
      dishId: this.props.dishId,
      dishName: this.props.dishName,
      quantity: 0,
      deliveryDate: this.props.deliveryDate,
      deliveryDates: this.props.deliveryDates,
      price: this.props.price,
      stockQuantity: [...this.props.stockQuantity],
      groupPromo: this.props.promoList,
      now: this.props.now,
      todayStock: this.props.todayStock,
    },
    activeCart: false, //ADD CANCEL FEATURE IN CART!!!
    chefNote: null,
    showPopup: false,
    time: Date.now(),
    userId: this.props.userId,
    redirect: false,
    modalComplete: false,
    modal: false,
    spiceRating: 0,
    weeklyStock: this.props.weeklyStock,
    weeklyQuantity: this.props.weeklyQuantity,
    //MUST HAVE ONE DATE PER ORDER i.e mon, tue ,wed, etc...
  };

  componentDidUpdate(prevProps) {
    if (prevProps.deliveryDate !== this.props.deliveryDate) {
      let currentDish = { ...this.state.currentDish };
      currentDish.deliveryDate = this.props.deliveryDate;
      this.setState({ currentDish });
    }
    // console.log("YO HERE CHECK IT", this.props.weeklyQuantity);
    if (prevProps.weeklyQuantity !== this.props.weeklyQuantity) {
      this.setState({ weeklyQuantity: this.props.weeklyQuantity });
    }
  }

  addQuantity = () => {
    let index = this.dateIndexMap[this.state.currentDish.deliveryDate]; //seems to have auto error handling.
    if (
      //CHECK!
      this.state.weeklyStock &&
      this.state.weeklyStock <
        this.state.weeklyQuantity + this.state.currentDish.quantity + 1
    ) {
      alert(`Unfortunately there is no more stock left. `);
      return;
    }
    if (
      this.state.currentDish.quantity >= 3 ||
      (index && !this.state.currentDish.stockQuantity[index]) ||
      (!this.state.currentDish.todayStock &&
        this.state.currentDish.deliveryDate === "Tod")
    ) {
      alert("max order exceeded / out of stock"); //pretify!
      return;
    } else if (!this.state.currentDish.deliveryDate === null) {
      alert("Please select a delivery date before adjusting order quantity");
      return;
    }
    let currentDish = { ...this.state.currentDish };
    currentDish.quantity = currentDish.quantity + 1;
    this.setState({
      currentDish,
    });
  };

  reduceQuantity = () => {
    if (this.state.currentDish.quantity - 1 < 0) {
      return;
    }
    let index = this.dateIndexMap[this.state.currentDish.deliveryDate];
    if (
      // this.state.currentDish.quantity == 0 ||
      !this.state.currentDish.stockQuantity[index] ||
      (!index &&
        index !== 0 &&
        !this.state.currentDish.todayStock &&
        !this.props.user.today)
    ) {
      console.log(
        "error here @ reduce",
        this.state.currentDish.stockQuantity[index],
        this.state.currentDish.deliveryDate,
        index
      );
      return;
    } else if (!this.state.currentDish.deliveryDate === null) {
      alert("Please select a delivery date before adjusting order quantity");
      return;
    } else {
      let currentDish = { ...this.state.currentDish };
      currentDish.quantity = currentDish.quantity - 1;
      // currentDish.todayStock = currentDish.todayStock + 1;
      this.setState({
        currentDish,
      });
    }
  };

  checkDateAndInsert = (currentDish, date) => {
    let actualDate =
      date === "Tod"
        ? DateLogic.addDays(0)
        : DateLogic.returnNewDate(date, false, this.props.dayPrep);
    let createdFor =
      date === "Tod"
        ? DateLogic.addDaysCreatedFor(0)
        : DateLogic.returnNewDate(date, true, this.props.dayPrep);
    // console.log(actualDate, createdFor);
    let pendingOrder = {
      phone: this.props.phone,
      address: this.props.address,
      chefId: this.props.chefId,
      dishId: this.state.currentDish.dishId,
      quantity: this.state.currentDish.quantity,
      // deliveryMode: "pickup",
      deliveryDate:
        currentDish.deliveryDate === "Tod"
          ? dateIndexMapRe[new Date().getDay()]
          : currentDish.deliveryDate,
      actualDate: actualDate,
      createdFor: createdFor,
      today: date === "Tod" ? true : false,
    };
    return pendingOrder;
  };

  reset = (currentDish) => {
    // currentDish.deliveryDate = null;
    currentDish.quantity = 0;
    //post axios request
    this.setState({
      activeCart: true,
      currentDish,
      cartLoading: false,
      // dishList,
    }); //switch
    setTimeout(() => {
      this.setState({ activeCart: false });
    }, 6000);
  };

  submitBooking = () => {
    this.setState({ cartLoading: true });
    if (
      (this.props.dishNote || this.props.tags.includes("spice")) &&
      !this.state.modalComplete
    ) {
      this.setState({ modal: true });
      return;
    }
    let index = this.dateIndexMap[this.state.currentDish.deliveryDate];
    // console.log("CUURENT", this.state.currentDish);
    // if (!this.props.stock) {
    //   return;
    // }
    if (this.state.currentDish.quantity == 0) {
      // // console.log(`DELIVERY DATE: ${this.state.deliveryDate}`);
      alert("Please put in some order quantity before adding to cart");
      this.setState({ cartLoading: false });
      return;
    }

    if (
      (!this.state.currentDish.stockQuantity[index] &&
        this.state.currentDish.deliveryDate !== "Tod") ||
      (!this.state.currentDish.todayStock &&
        this.state.currentDish.deliveryDate === "Tod")
    ) {
      this.setState({ cartLoading: false });
      alert("This dish is out of stock!");
      return;
    }

    //POST REQUEST FOR ORDER s
    let date = this.state.currentDish.deliveryDate;
    // if (date === "Tod") date = dateIndexMapRe[new Date().getDay()]; //FOR TODAY
    // // console.log("BOOKING" + date);
    let currentDish = { ...this.state.currentDish };
    let pendingOrder = this.checkDateAndInsert(currentDish, date); //order doesn't exist. SEPARATE METHOD in backend to a check if it exists or not. OR maybe just call one of them
    if (this.props.user || this.props.userId) {
      //set all location info for delivery here.
      pendingOrder.lat = this.props.user.location.coordinates[1];
      pendingOrder.lng = this.props.user.location.coordinates[0];
      pendingOrder.address = this.props.user.address;
      pendingOrder.name = this.props.user.name;
      pendingOrder.phone = this.props.user.phone;
      pendingOrder.chefNote = this.state.chefNote;
      pendingOrder.apt_number = this.props.user.apt_number;
      pendingOrder.spiceRating = this.state.spiceRating;
      pendingOrder.weeklyStock = this.state.weeklyStock;
      pendingOrder.weeklyQuantity = this.state.weeklyQuantity;
      // console.log(pendingOrder.address, pendingOrder.name, pendingOrder.phone);
      this.createOrder(pendingOrder, currentDish, false);
    } else {
      pendingOrder.lat = Store.getLat();
      pendingOrder.lng = Store.getLng();
      pendingOrder.address = Store.getAddress();
      pendingOrder.chefNote = this.state.chefNote;
      pendingOrder.spiceRating = this.state.spiceRating;
      pendingOrder.weeklyStock = this.state.weeklyStock;
      pendingOrder.weeklyQuantity = this.state.weeklyQuantity;
      Store.setOrder(pendingOrder);
      this.setState({ redirect: true });
      this.props.setHistoryLink(`/menu/${this.props.chefId}`);
    }
    //RESET
  };

  createOrder = (pendingOrder, currentDish, login) => {
    axios({
      method: "post",
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/orders`,
      withCredentials: true,
      data: pendingOrder,
    })
      .then((response) => {
        if (response.data.status === "success") {
          if (!login) this.reset(currentDish);
          this.props.setDate(new Date());
          this.setState({ weeklyQuantity: response.data.weeklyQuantity });
        } else {
          alert(
            "Your order did not go through! Sorry for the inconvenience, please try again later"
          );
          this.setState({
            activeCart: false,
            cartLoading: false,
            modal: false,
            modalComplete: false,
            // spiceRating: 0,
          });
        }
      })
      .catch((err) => {
        alert(
          "Your order did not go through! Sorry for the inconvenience, please try again later"
        );
        currentDish.quantity = 0;
        this.setState({
          activeCart: false,
          currentDish,
          cartLoading: false,
          modal: false,
          modalComplete: false,
          // spiceRating: 0,
        });
      });
  };

  showPopup = () => {
    // // console.log("show POPUP!");
    this.setState({ showPopup: true });
  };

  closePopup = () => {
    this.setState({ showPopup: false });
  };

  componentDidMount() {
    // console.log("@@@ MOUNNTTT");
    if (this.props.fromLogin) {
      //i.e histo link not null meaning we just loggedin.
      //ONLY RUN ONCE CAUSE NULL
      let pendingOrder = Store.getOrder();
      if (pendingOrder) {
        this.createOrder(pendingOrder, null, true);
        Store.setOrder(null);
        alert(
          `We have added the previous order you tried to add to cart already :) You can visit your cart above to see the order or continue shopping. Thank you for your support`
        );
      }

      console.log(
        "check",
        this.props.dailyPrep,
        DateLogic.returnNewDate(
          this.state.currentDish.deliveryDate,
          false,
          this.props.dailyPrep
        ),
        DateLogic.returnNewDate(
          this.state.currentDish.deliveryDate,
          false,
          this.props.dayPrep
        )
      );

      this.props.setHistoryLink(null);
    }
  }

  dateIndexMap = {
    //note taht sotck is for mon = 0 so this is g
    Mon: 0,
    Tue: 1,
    Wed: 2,
    Thu: 3,
    Fri: 4,
    Sat: 5,
    Sun: 6,
  };

  deselectDate = () => {
    let currentDish = { ...this.state.currentDish };
    currentDish.deliveryDate = null;
    this.setState({ currentDish });
  };

  capitalizeFirstLetter(string) {
    let str = string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
    let arr = str.split(" ");
    return arr[0];
  }
  getLastTimeBefore = () => {
    var time = 10;
    // console.log(this.state.currentDish.deliveryDate);
    var date = this.state.currentDish.deliveryDate;
    if (date === "Tod") date = dateIndexMapRe[new Date().getDay()];
    this.props.deliveryTimes[date].forEach((t) => {
      if (t != null) {
        time = t;
      }
    });
    return time;
  };
  render() {
    // // console.log(
    //   this.state.currentDish.stockQuantity[
    //     this.dateIndexMap[this.state.currentDish.deliveryDate]
    //   ],
    //   this.state.currentDish.deliveryDate
    // );
    // // console.log(
    //   this.state.currentDish.deliveryDate,
    //   this.state.currentDish.todayStock,
    //   this.state.currentDish.stockQuantity[
    //     this.dateIndexMap[this.state.currentDish.deliveryDate]
    //   ]
    // );
    // console.log("ots", this.state.weeklyStock, this.state.weeklyQuantity);
    if (this.state.redirect) return <Redirect to="/login" />;
    return (
      <div className="menu_dish_card">
        {((this.state.currentDish.deliveryDate != "Tod" &&
          this.state.currentDish.stockQuantity[
            this.dateIndexMap[this.state.currentDish.deliveryDate] //today orders
          ]) ||
          (this.state.currentDish.deliveryDate === "Tod" &&
            this.state.currentDish.todayStock &&
            this.getLastTimeBefore() > new Date().getHours())) &&
        !(
          this.props.dailyPrep && //check for override dailyPrep
          this.state.currentDish.deliveryDate != "Tod" &&
          DateLogic.returnNewDate(
            this.state.currentDish.deliveryDate,
            false,
            this.props.dailyPrep
          ) !==
            DateLogic.returnNewDate(
              this.state.currentDish.deliveryDate,
              false,
              this.props.dayPrep
            )
        ) &&
        !(
          (this.state.weeklyStock || this.state.weeklyStock === 0) &&
          this.props.weeklyStock - this.props.weeklyQuantity <= 0 && //stock
          this.state.weeklyStock - this.state.weeklyQuantity <= 0
        ) &&
        new Date().getHours() <= 20 &&
        new Date().getHours() >= 8 ? (
          // 10am to 10pm  ( //may reduce orders unnesecarily this way i.e before checkout might be left. But ok as safety precaution remove later and put it in checkout logic. Better UX also.
          <div></div>
        ) : new Date().getHours() >= 20 ? (
          <div className="out_of_stock">NO ORDERS AFTER 8PM</div>
        ) : new Date().getHours() <= 8 ? (
          <div className="out_of_stock">NO ORDERS BEFORE 8AM</div>
        ) : (
          <div className="out_of_stock">OUT OF STOCK</div>
        )}
        {this.state.modal ? (
          <div className="popup_assortment">
            {/* {this.props.brandPhoto ? (
              <img
                src={this.props.brandPhoto}
                alt=""
                className="image brand_image_popup"
              />
            ) : (
              <div></div>
            )} */}
            <div className="container_assort">
              <i
                className="fas fa-window-close window_close_fc"
                onClick={() => {
                  this.setState({ modal: false, cartLoading: false });
                }}
              ></i>
              <p style={{ fontFamily: "landingFontTwo", maxWidth: "50%" }}>
                <b>{this.props.note}</b>
              </p>
              {this.props.note ? (
                <textarea
                  name=""
                  id=""
                  cols="80"
                  rows="5"
                  onChange={(e) => {
                    this.setState({ chefNote: e.target.value });
                  }}
                  style={{ cursor: "text", fontFamily: "landingFontTwo" }}
                ></textarea>
              ) : null}
              {console.log(this.props.tags)}
              {this.props.tags && this.props.tags.includes("spice") ? (
                <div className="gen_wrap">
                  <h1 style={{ fontFamily: "landingFontTwo", padding: "20px" }}>
                    Please select a spice level
                  </h1>
                  <div
                    className="flex_cont"
                    style={{ padding: "20px", flexWrap: "wrap" }}
                  >
                    <div>
                      <input
                        type="radio"
                        className="radio_button_time"
                        name="spice"
                        id={"spice_1"}
                        value={1}
                        onClick={() => this.setState({ spiceRating: 0 })} //CHEFS need to finish by that hour time! Make sure to always give hour + 1
                      />
                      <label
                        htmlFor={"spice_1"}
                        style={{
                          marginLeft: "10px",
                          margin: "10px",
                          fontFamily: "landingFontTwo",
                        }}
                      >
                        No Spice
                      </label>
                      <input
                        type="radio"
                        className="radio_button_time"
                        name="spice"
                        id={"spice_1"}
                        value={1}
                        onClick={() => this.setState({ spiceRating: 1 })} //CHEFS need to finish by that hour time! Make sure to always give hour + 1
                      />
                      <label
                        htmlFor={"spice_1"}
                        style={{
                          marginLeft: "10px",
                          margin: "10px",
                          fontFamily: "landingFontTwo",
                        }}
                      >
                        Low Spice
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        className="radio_button_time"
                        name="spice"
                        id={"spice_2"}
                        value={2}
                        onClick={() => this.setState({ spiceRating: 2 })} //CHEFS need to finish by that hour time! Make sure to always give hour + 1
                      />
                      <label
                        htmlFor={"spice_2"}
                        style={{
                          marginLeft: "10px",
                          margin: "10px",
                          fontFamily: "landingFontTwo",
                        }}
                      >
                        Medium Spice
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        className="radio_button_time"
                        name="spice"
                        id={"spice_3"}
                        value={3}
                        onClick={() => this.setState({ spiceRating: 3 })} //CHEFS need to finish by that hour time! Make sure to always give hour + 1
                      />
                      <label
                        htmlFor={"spice_3"}
                        style={{
                          marginLeft: "10px",
                          margin: "10px",
                          fontFamily: "landingFontTwo",
                        }}
                      >
                        Medium-High Spice
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        className="radio_button_time"
                        name="spice"
                        id={"spice_4"}
                        value={4}
                        onClick={() => this.setState({ spiceRating: 4 })} //CHEFS need to finish by that hour time! Make sure to always give hour + 1
                      />
                      <label
                        htmlFor={"spice_4"}
                        style={{
                          marginLeft: "10px",
                          margin: "10px",
                          fontFamily: "landingFontTwo",
                        }}
                      >
                        High Spice
                      </label>
                    </div>
                  </div>
                </div>
              ) : // <Rating
              //   name="simplcontrolled"
              //   value={this.state.spiceRating}
              //   onChange={(event, newValue) => {
              //     this.setState({ spiceRating: newValue });
              //   }}
              //   icon={
              //     <i
              //       className="fas fa-pepper-hot"
              //       title="spicy"
              //       style={{ color: "red" }}
              //       title="spicy"
              //       key="spicy"
              //     ></i>
              //   }
              // />
              null}
              <div
                className="cart_text special_cart_text"
                style={{ marginTop: "20px" }}
                onClick={async () => {
                  await this.setState({ modal: false, modalComplete: true });
                  this.submitBooking();
                }}
              >
                Submit
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
        <div
          className="detailPopup"
          style={{
            position: "fixed",
            display: this.state.showPopup ? "block" : "none",
          }}
        >
          <div className="top_header_detail">
            <div className="dish_detail_header">
              {this.state.currentDish.dishName.toUpperCase()}
            </div>
            <div className="close_button">
              <i className="fas fa-times" onClick={this.closePopup}></i>
            </div>
          </div>
          <div className="content">
            <p className="about_dish_detail">
              <b>About</b> <br />{" "}
              <span className="about_description">
                {this.props.description}
              </span>
            </p>
            <p className="allergy_warnings">
              <b>Allergy Warnings</b> <br />
              <span className="allergy_description">
                {this.props.allergyDescription}
              </span>
            </p>
          </div>
        </div>
        {this.props.ratingsAverage && this.props.ratingsAverage !== 0 ? (
          <div className="flex_cont ratings_circle">
            <i class="fas fa-star"></i>
            {(Math.round(this.props.ratingsAverage * 100) / 100).toFixed(2)}
          </div>
        ) : (
          <div className="flex_cont ratings_circle">
            <i class="fas fa-star"></i> No Ratings
          </div>
        )}
        <div
          className="background_image image"
          style={{
            backgroundImage: `url(${this.props.photo})`,
          }}
        ></div>
        <div className="menu_dish_photo_m image"></div>
        <div className="flex_cont_header">
          <h1 className="dish_name">{this.props.dishName}</h1>
          <div className="tag_logos">
            {this.props.tags.map((tag) => {
              return this.props.iconMap[tag];
            })}
            {this.state.currentDish.groupPromo ? (
              this.props.iconMap["group"]
            ) : (
              <div> </div>
            )}
          </div>
        </div>
        <p className="menu_dish_description">{this.props.description}</p>
        <span className="see_more_menu" onClick={this.showPopup}>
          See More
        </span>
        <p className="serving_size_menu stock_quantity">
          <b>Serving Size: </b>
          {`${this.props.servingSize ? this.props.servingSize : ""} ${
            this.props.unit
          }`}
        </p>
        {this.state.weeklyStock ? (
          <p className="serving_size_menu stock_quantity">
            <b>Stock Left: </b>
            {`${this.state.weeklyStock - this.state.weeklyQuantity} ${
              this.state.weeklyStock - this.state.weeklyQuantity === 1
                ? " Order"
                : "Orders"
            }`}
          </p>
        ) : null}
        <div className="menu_dish_bottom">
          <div className="order_quantity">
            <div className="minus_order" onClick={this.reduceQuantity}>
              <i className="fas fa-minus"></i>
            </div>
            <div className="space_order">{this.state.currentDish.quantity}</div>
            <div className="add_order" onClick={this.addQuantity}>
              <i className="fas fa-plus"></i>
            </div>
          </div>
          <div className="price_tag_menu_u">{this.props.price}.00P</div>
          <div className="cart" onClick={this.submitBooking}>
            {this.state.activeCart ? (
              <div>
                <i className="far fa-check-circle active_cart"></i>
                <p
                  style={{
                    color: "yellowgreen",
                    fontSize: "11px",
                    fontFamily: "landingFontTwo",
                  }}
                >
                  Order has been added to cart
                </p>
              </div>
            ) : !this.state.cartLoading ? (
              <div className="cart_text">ADD TO CART</div>
            ) : (
              <p>Loading...</p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default FoodMenuItem;
