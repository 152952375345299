import React, { Component } from "react";
import MenuItem from "./menu_food";
import DateLogic from "../../util/dateFunctions";
import MenuDates from "./menuDatePicker";
import Select from "react-select";
import Store from "../Store";
import MiniCart from "./miniCart";
import { Helmet } from "react-helmet";

import StarRatings from "react-rating-stars-component";
import {
  FacebookShareButton,
  WhatsappShareButton,
  FacebookIcon,
  WhatsappIcon,
} from "react-share";
import axios from "axios";

import "./menu.css";
export class menu extends Component {
  state = {
    user: {},
    indexGroupMon: new Set(),
    indexGroupTue: new Set(),
    indexGroupWed: new Set(),
    indexGroupThu: new Set(),
    indexGroupFri: new Set(),
    indexGroupSat: new Set(),
    indexGroupSun: new Set(),
    loading: true,
    deliveryTimes: {},
    menus: {}, //Store.getMenu(),
    dishesMap: {},
    selected: "", //Store.getSelectedSimple(),
    distance: null,
    time: null,
    todayStock: false, //Store.getTodayStock(),
    pendingOrderDate: new Date(),
    weeklyQuantity: 0,
    // notify: false,
  };
  // componentDidUpdate(prevProps, prevState)
  dateIndexMap = {
    Mon: 0,
    Tue: 1,
    Wed: 2,
    Thu: 3,
    Fri: 4,
    Sat: 5,
    Sun: 6,
  };

  dateProperMap = {
    Sun: 0,
    Mon: 1,
    Tue: 2,
    Wed: 3,
    Thu: 4,
    Fri: 5,
    Sat: 6,
  };

  dateProperMapRe = {
    0: "Sun",
    1: "Mon",
    2: "Tue",
    3: "Wed",
    4: "Thu",
    5: "Fri",
    6: "Sat",
  };

  dayMap = {
    Mon: "Monday",
    Tue: "Tuesday",
    Wed: "Wednesday",
    Thu: "Thursday",
    Fri: "Friday",
    Sat: "Saturday",
    Sun: "Sunday",
  };

  convertUTCDateToLocalDate(date) {
    var newDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60 * 1000
    );
    return newDate;
  }

  sortObj = (menus) => {
    let newMenu = {};
    if (menus.Mon) newMenu.Mon = menus.Mon;
    if (menus.Tue) newMenu.Tue = menus.Tue;
    if (menus.Wed) newMenu.Wed = menus.Wed;
    if (menus.Thu) newMenu.Thu = menus.Thu;
    if (menus.Fri) newMenu.Fri = menus.Fri;
    if (menus.Sat) newMenu.Sat = menus.Sat;
    if (menus.Sun) newMenu.Sun = menus.Sun;
    return newMenu;
  };

  findDeliveryDates = (user, dishes) => {
    let menus = {};
    let todayStock = false;
    Array.from(dishes).forEach((dish) => {
      if (dish.todayStock && user.today) todayStock = true;
      //might be better to create a mapping of dishId to dish and then array only has ids, accesses this obj. Under the hood address pointer anyways no?
      Array.from(dish.deliveryDates).forEach((day) => {
        // // console.log("day, ", day);
        if (day !== "") {
          Array.isArray(menus[day])
            ? menus[day].push(dish)
            : (menus[day] = [dish]);
        }
      });
    });
    menus = this.sortObj(menus);
    if (todayStock) this.setState({ todayStock });
    // // console.log("TODAY STOCK", todayStock);
    // // console.log("MENU/DATE", menus);
    return menus;
  };

  getDistance = (user) => {
    // // console.log("LAT", Store.getLat(), Store.getLng());
    axios({
      method: "get",
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/users/distance/${this.props.user._id}?lat=${
        user.location.coordinates[1]
      }&lng=${user.location.coordinates[0]}`,
      withCredentials: true,
    })
      .then((response) => {
        if (response.data.status === "success") {
          let num = response.data.data.distances[0].distance;
          this.setState({ distance: Math.round(num * 100) / 100 });
          return;
        } else {
          // console.log("response for location collection was not succesful");
          return; //just do nothing i.e don't display distance.
        }
      })
      .catch((err) => {
        console.log("DISTANCE calc error", err);
      });
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  returnFirst = (arr) => {
    let min = null;
    Array.from(arr).forEach((e) => {
      if (min === null || this.dateIndexMap[e] < this.dateIndexMap[min]) {
        min = e;
      }
    });
    // console.log("return first", arr);
    return min;
  };

  axiosGet = () => {
    axios({
      method: "get",
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/users/menu/${this.props.match.params.chefId}`,
      withCredentials: true,
    }).then(async (response) => {
      if (response.data.status === "success") {
        let user = response.data.data.user;
        // // console.log(this.state.menus, this.state.menus.size);
        let menus =
          // Object.keys(this.state.menus).length === 0 ?
          this.findDeliveryDates(user, user.dishes);
        // : this.state.menus;
        // // console.log("MENUS", menus);
        // console.log("compdidmount", menus);
        if (this.props.user) this.getDistance(user);
        await this.setState({
          menus,
          selected: Store.getSelectedDate(
            this.state.todayStock,
            this.returnFirst(user.menuDates)
            //Object.keys(menus)[0]
          ), //this.state.todayStock ? "Tod" : Object.keys(menus)[0],
          user,
          loading: false,
          deliveryTimes: {
            Mon: user.deliveryTimesMon,
            Tue: user.deliveryTimesTue,
            Wed: user.deliveryTimesWed,
            Thu: user.deliveryTimesThu,
            Fri: user.deliveryTimesFri,
            Sat: user.deliveryTimesSat,
            Sun: user.deliveryTimesSun,
          },
        });
        // // console.log("MENU RETRIEVED SUCCESFULLY", user);
        this.findGroupIndicesAll();
      } else {
        alert(
          "There was an issue regarding getting the chef. Please try again later!"
        );
      }
      // FIND PENDING ORDERS
      //     axios({
      //       method: "get",
      //       url: `${process.env.NODE_ENV === "production" ? process.env.REACT_APP_URL : "http://localhost:8080.com"}/api/v1/orders?user=${this.props.userId}chef=${user.id}payed=false&finished=false`,
      //       withCredentials: true,
      //     }).then((response) => {
      //       this.setState({ pendingOrders: response.data.data.data });
      //     });
      //   } else {
      //     alert(
      //       "There was an issue regarding getting the chefs. Please try again later!"
      //     );
      //   }
      // });
    });
  };

  componentDidMount() {
    //FETCH DISHES HERE THEN ON COMPLETE... find group indices
    window.scrollTo(0, 0);
    this.setState({ loading: true });
    this.axiosGet();
    this.interval = setInterval(
      () => this.axiosGet(),
      1000 * 30 //every 20 minutes where component is still there //IS THIS TOO MUCH?
    );
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    Store.setSelectedDate("");
    Store.setTodayStock(false);
    Store.setMenu({});
  }

  // updatePendingOrders = (pendingOrders) => {
  //   this.setState({ pendingOrders });
  // };

  findGroupIndicesAll = () => {
    this.findGroupIndices("Mon");
    this.findGroupIndices("Tue");
    this.findGroupIndices("Wed");
    this.findGroupIndices("Thu");
    this.findGroupIndices("Fri");
    this.findGroupIndices("Sat");
    this.findGroupIndices("Sun");
  };
  findGroupIndices = (day) => {
    let indexGroup = new Set();
    if (this.state.menus[day]) {
      this.state.menus[day].forEach((dish, i) => {
        if (dish.groupPromo) {
          indexGroup.add(i);
        }
      });
    }
    this.setState({ [`indexGroup${day}`]: indexGroup });
    // // console.log(this.indexGroup);
  };

  weeklyQuantityNotify = () => {
    this.axiosGet();
    // this.setState({ weeklyQuantity });
  };

  // stockUpdate = (add, index, quantity, date) => {
  //   //add always false
  //   let user = { ...this.state.user };
  //   let dishes = user.dishes;
  //   if (add) {
  //     dishes[index].stockQuantity[this.dateIndexMap[date]] =
  //       dishes[index].stockQuantity[this.dateIndexMap[date]] + quantity;
  //   } else {
  //     dishes[index].stockQuantity[this.dateIndexMap[date]] =
  //       dishes[index].stockQuantity[this.dateIndexMap[date]] - quantity;
  //   }
  //   this.setState({ user });
  // };

  updateSelect = (selected) => {
    this.setState({ selected: selected.value });
    Store.setSelectedDate(selected.value);
    // // console.log(`Option selected:`, selected);
  };

  condenseMenuDates = (obj) => {
    if (!obj.Mon) delete obj.Mon;
    if (!obj.Tue) delete obj.Tue;
    if (!obj.Wed) delete obj.Wed;
    if (!obj.Thu) delete obj.Thu;
    if (!obj.Fri) delete obj.Fri;
    if (!obj.Sat) delete obj.Sat;
    if (!obj.Sun) delete obj.Sun;
    return obj;
  };

  convertToObj = (arr) => {
    let obj = {};
    Array.from(arr).forEach((e) => {
      obj[e] = true;
    });
    return this.sortObj(obj);
  };

  getOptionArray = () => {
    // // console.log(Object.keys(this.state.menus));
    let menuDatesArr = [...this.state.user.menuDates];
    let menuDates = this.convertToObj(menuDatesArr);
    // console.log("menu dates prior to delete", menuDates);
    menuDates = this.condenseMenuDates(menuDates);
    // console.log("menu dates post delete", menuDates);
    let arr = [];
    if (menuDates) {
      arr = Object.keys(menuDates).map((day) => {
        return {
          value: day,
          label: `MENU FOR: ${this.dayMap[day]}, ${DateLogic.returnNewDate(
            day,
            false,
            this.state.user.dayPrep
          )}`.toUpperCase(),
        };
      });
    }
    if (this.state.todayStock) {
      arr.unshift({
        value: "Tod",
        label: `MENU FOR: ${
          this.dateProperMapRe[new Date().getDay()]
        }, ${DateLogic.addDays(0)} (TODAY)`.toUpperCase(),
      });
    }
    // // console.log("THIS IS THE ARRAY", arr);
    return arr;
  }; //ADJUST FOR TOD IN ORDER MGMT!

  setDate = (date) => {
    this.setState({ pendingOrderDate: date });
  };

  render() {
    if (!this.state.loading) {
      return (
        <section className="chef_menu">
          <Helmet>
            <title>
              tizaar - {this.state.user.name ? this.state.user.name : "unknown"}
              's Menu
            </title>
            <meta
              name="description"
              content="Tickle your taste buds at tizaar's food bazaar and unconver hidden food delights right in your very neighborhood!"
            />
          </Helmet>
          <div className="container_homechef">
            <div className="menu_main">
              {/* <MenuDates
                menus={this.state.user ? this.state.user.menus : null}
              /> */}
              <div className="left_main_container">
                <div style={{ width: "100%", position: "relative" }}>
                  <div
                    className={
                      this.state.user.rectangle
                        ? "chef_image_menu image rectangle"
                        : "chef_image_menu image"
                    }
                    style={{
                      backgroundImage: `url(${this.state.user.userPhoto})`,
                    }}
                  ></div>
                  {/* <div className={"brand_photo"} style={{ cursor: "none" }}>
                    <div
                      className={
                        this.state.user.contain
                          ? "brand_photo_image_m image contain"
                          : "brand_photo_image_m image"
                      }
                      style={{
                        backgroundImage: `url(${this.state.user.brandPhoto})`,
                        cursor: "initial",
                      }}
                    ></div>
                  </div> */}
                </div>
              </div>
              <div className="right_main_container">
                <h1
                  className="chef_tag"
                  style={{
                    color: "#f9603f",
                    fontFamily: "landingFontTwo",
                    textAlign: "start",
                    marginTop: "20px",
                  }}
                >
                  CHEF
                </h1>
                <h1
                  className="chef_name_bold"
                  style={{
                    fontFamily: "p-bold",
                    color: "white",
                    textAlign: "start",
                    fontSize: "50px",
                    textTransform: "uppercase",
                  }}
                >
                  {this.state.user.name}'S{" "}
                  <span
                    style={{
                      color: "#fbd36c",
                      fontFamily: "landingFontTwo",
                    }}
                  >
                    MENU
                  </span>
                </h1>
                <div className="chef_cuisine">
                  {this.state.user.cuisine.join(", ")}
                </div>
                {this.state.user.ratingsQuantity > 7 &&
                this.state.user.ratingsAverage > 3.5 ? (
                  <div className="rating_flex">
                    <p className="ratingScore">
                      {this.state.user.ratingsAverage}
                    </p>
                    <StarRatings
                      value={this.state.user.ratingsAverage}
                      count={5}
                      size={20}
                      isHalf={true}
                      emptyIcon={<i className="far fa-star"></i>}
                      halfIcon={<i className="fa fa-star-half-alt"></i>}
                      fullIcon={<i className="fa fa-star"></i>}
                      edit={false}
                    />
                    <p className="ratingQuantity">
                      ({this.state.user.ratingsQuantity})
                    </p>
                  </div>
                ) : (
                  <div className="new_tag_fi">NEW CHEF</div>
                )}
                <p className="auto-bio">{this.state.user.description}</p>
                {/* <div className="flex_container">
                  <p style={{ fontFamily: "landingFontTwo" }}>
                    Possible Delivery Dates
                  </p>
                  <div className="days">
                    <div className="date_bubble">Mon</div>
                    <div className="date_bubble">Tue</div>
                    <div className="date_bubble">Wed</div>
                    <div className="date_bubble">Thu</div>
                    <div className="date_bubble">Fri</div>
                    <div className="date_bubble">Sat</div>
                    <div className="date_bubble">Sat</div>
                  </div>
                </div> */}
                <div
                  className="flex_container"
                  style={{ marginTop: "10px" }}
                ></div>
                <div className="flex_start">
                  <span className=" dairy spicy_key flex_start">
                    Dairy:{" "}
                    <img
                      src={`${process.env.PUBLIC_URL}/menu/dairy.svg`}
                      style={{
                        width: "16px",
                        height: "16px",
                        marginLeft: "5px",
                      }}
                      alt=""
                    />
                  </span>
                  <span
                    className="spicy_key flex_start"
                    style={{ marginLeft: "10px" }}
                  >
                    Spicy:{" "}
                    <i
                      className="fas fa-pepper-hot"
                      style={{ color: "red" }}
                    ></i>
                  </span>
                  <span
                    className="vegetarian flex-start spicy_key"
                    style={{ marginLeft: "10px" }}
                  >
                    Vegetarian:{" "}
                    <i className="fas fa-leaf" style={{ color: "green" }}></i>
                  </span>
                  <span
                    className="group_order spicy_key flex_start"
                    style={{ marginLeft: "10px" }}
                  >
                    Group Promos and Meal Trays:{" "}
                    <i
                      className="fas fa-users"
                      style={{ color: "#fcdb03" }}
                      title="group promo"
                    ></i>
                  </span>
                </div>
                <div className="flex_start">
                  <p
                    className="distance_menu_tag"
                    style={{ fontFamily: "landingFont" }}
                  >
                    <b>
                      Distance:{" "}
                      {this.props.user
                        ? `${this.state.distance} km`
                        : "Please log in to see exact distance away"}
                    </b>
                  </p>
                </div>
              </div>
              <div className="social_share">
                {" "}
                Share Menu
                <FacebookShareButton
                  url={`https://www.tizaar.com/menu/${this.props.match.params.chefId}`}
                >
                  <FacebookIcon
                    size={"1.5rem"} // You can use rem value instead of numbers
                    round
                    className="fab"
                  />
                </FacebookShareButton>
                <WhatsappShareButton
                  url={`https://www.tizaar.com/menu/${this.props.match.params.chefId}`}
                >
                  <WhatsappIcon
                    size={"1.5rem"} // You can use rem value instead of numbers
                    round
                    className="fab"
                  />
                </WhatsappShareButton>
                {/* <i
                className="fab fa-instagram"
                size={32}
                round={true}
                iconFillColor="white"
              ></i> */}
              </div>
            </div>
            {Object.keys(this.state.menus).length > 0 &&
            this.returnFirst(this.state.user.menuDates) !== undefined &&
            this.state.user.menuDates.length > 0 ? ( //must have a date selected. Can have in menuDates but not in date cause taking a break.
              <Select
                className="selector"
                isSearchable={false}
                placeholder={
                  this.state.selected !== "Tod"
                    ? `MENU FOR: ${
                        this.dayMap[this.state.selected]
                      }, ${DateLogic.returnNewDate(
                        this.state.selected,
                        false,
                        this.state.user.dayPrep
                      )}`.toUpperCase()
                    : `MENU FOR: ${
                        this.dateProperMapRe[new Date().getDay()]
                      }, ${DateLogic.addDays(0)} (TODAY)`.toUpperCase()
                }
                value={this.state.selected} //either Mon, Tue, Wed...Sun, OR Tod
                onChange={this.updateSelect}
                style={{ cursor: "pointer" }}
                options={this.getOptionArray()}
                color="black"
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: "orange",
                    primary: "black",
                  },
                })}
              ></Select>
            ) : (
              <div className="empty" style={{ padding: "40px" }}>
                Not Available Yet
              </div>
            )}
            {/* <div className="when_date flex"> */}
            {/* {// console.log(
              "MENU XC",
              this.state.menus[
                this.state.selected === "Tod"
                  ? this.dateProperMapRe[new Date().getDay()]
                  : this.state.selected
              ],
              "DATE",
              this.state.selected
            )} */}
            {/* {console.log(
              "SIZE ISSUE PRIOR",
              this.state.menus,
              this.state.selected,
              this.state[`indexGroup${this.state.selected}`]
            )} */}
            {Object.keys(this.state.menus).length > 0 &&
            this.returnFirst(this.state.user.menuDates) !== undefined &&
            this.state.user.menuDates.length > 0 ? (
              <div style={{ display: "flex", alignSelf: "flex-start" }}>
                <section className="menu_item_section">
                  {this.state[
                    `indexGroup${
                      this.state.selected === "Tod"
                        ? this.dateProperMapRe[new Date().getDay()]
                        : this.state.selected
                    }`
                  ].size === this.state.user.dishes.length ||
                  this.state.menus[
                    this.state.selected === "Tod"
                      ? this.dateProperMapRe[new Date().getDay()]
                      : this.state.selected
                  ] == undefined ? (
                    <div></div>
                  ) : (
                    <div>
                      <div className="menu_items" style={{ marginTop: "20px" }}>
                        <MenuItem
                          dishes={
                            this.state.menus[
                              this.state.selected === "Tod"
                                ? this.dateProperMapRe[new Date().getDay()]
                                : this.state.selected
                            ]
                          } //this.state.user.dishes
                          dayPrep={this.state.user.dayPrep}
                          deliveryTimes={this.state.deliveryTimes}
                          setDate={this.setDate}
                          pickupAddress={this.state.user.address}
                          fromLogin={this.props.fromLogin}
                          chefId={this.state.user.id}
                          brandPhoto={this.state.user.brandPhoto}
                          userId={this.props.userId}
                          user={this.props.user}
                          setHistoryLink={this.props.setHistoryLink}
                          firstName={this.state.user.name}
                          selectedDay={this.state.selected} //if selectedDay = tod will have to adj order
                          // pendingOrders={this.state.pendingOrders}
                          // updatePendingOrders={this.updatePendingOrders}
                          // stockUpdate={this.stockUpdate}
                          indexGroup={
                            this.state[
                              `indexGroup${
                                this.state.selected === "Tod"
                                  ? this.dateProperMapRe[new Date().getDay()]
                                  : this.state.selected
                              }`
                            ]
                          }
                          promoList={false}
                        />
                      </div>
                    </div>
                  )}
                  {this.state[
                    `indexGroup${
                      this.state.selected === "Tod"
                        ? this.dateProperMapRe[new Date().getDay()]
                        : this.state.selected
                    }`
                  ].size > 0 &&
                  this.state.menus[
                    this.state.selected === "Tod"
                      ? this.dateProperMapRe[new Date().getDay()]
                      : this.state.selected
                  ] !== undefined ? (
                    <div>
                      {/* {// console.log("size is G")} */}
                      <h1 className="main_menu_i">Meal Trays & Promos</h1>
                      <div className="menu_items">
                        <MenuItem
                          dishes={
                            this.state.menus[
                              this.state.selected === "Tod"
                                ? this.dateProperMapRe[new Date().getDay()]
                                : this.state.selected
                            ]
                          } //this.state.user.dishes
                          dayPrep={this.state.user.dayPrep}
                          setDate={this.setDate}
                          deliveryTimes={this.state.deliveryTimes}
                          brandPhoto={this.state.user.brandPhoto}
                          pickupAddress={this.state.user.address}
                          chefId={this.state.user.id}
                          userId={this.props.userId}
                          user={this.props.user}
                          setHistoryLink={this.props.setHistoryLink}
                          firstName={this.state.user.name}
                          selectedDay={this.state.selected} //if selectedDay = Tod will have to adj order
                          fromLogin={this.props.fromLogin}
                          // pendingOrders={this.state.pendingOrders}
                          // updatePendingOrders={this.updatePendingOrders}
                          // stockUpdate={this.stockUpdate}
                          indexGroup={
                            this.state[
                              `indexGroup${
                                this.state.selected === "Tod"
                                  ? this.dateProperMapRe[new Date().getDay()]
                                  : this.state.selected
                              }`
                            ]
                          }
                          promoList={true}
                        />
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                  {this.state.menus[
                    this.state.selected === "Tod"
                      ? this.dateProperMapRe[new Date().getDay()]
                      : this.state.selected
                  ] === undefined ? (
                    <div className="empty">NOT AVAILABLE</div>
                  ) : (
                    <div style={{ display: "none" }}></div>
                  )}
                </section>
                <MiniCart
                  chef={this.state.user}
                  pendingOrderDate={this.state.pendingOrderDate}
                  notify={this.weeklyQuantityNotify}
                />
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </section>
      );
    } else {
      return (
        <div className="fullheight">
          <div
            className="chef_float"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/landing/chef.png)`,
            }}
          ></div>
        </div>
      );
    }
  }
}

export default menu;
