var o = {};
var filters = {};
var selectedDate = "";
var todayStock = false;
var menus = {};
var order = {};
var lat = null;
var lng = null;
var address = null;
var main = "The Cooks";

var Store = {
  saveQuery: function (state) {
    o = state;
  },
  getQuery: function () {
    return o;
  },
  setActive: function (key) {
    filters[key] = true;
  },
  setInactive: function (key) {
    filters[key] = false;
    // // console.log(filters);
  },
  setMain: function (key) {
    main = key;
  },
  getMain: function () {
    return main;
  },
  getActive: function (key) {
    // console.log("get active", filters.key);
    if (filters[key]) return true;
    return false;
  },
  getSelectedDate: function (todayStock, firstVal) {
    if (selectedDate == "") {
      if (todayStock) {
        selectedDate = "Tod";
        // console.log("AT TODAY STOCK");
      } else {
        selectedDate = firstVal;
        // console.log("AT SZTORE", firstVal);
      }
    }
    // console.log("AT SZTORE", selectedDate);
    return selectedDate;
  },
  setSelectedDate: function (date) {
    selectedDate = date;
  },
  setTodayStock: function (todayStock) {
    todayStock = todayStock;
  },
  setMenu: function (menu) {
    menu = menu;
  },
  getMenu: function (menu) {
    return menu;
  },
  getSelectedSimple: function () {
    return selectedDate;
  },
  getTodayStock: function () {
    return todayStock;
  },
  getOrder: function () {
    return order;
  },
  setOrder: function (prevOrder) {
    order = prevOrder;
  },
  getLat: function () {
    return lat;
  },
  setLat: function (lati) {
    lat = lati;
  },
  getLng: function () {
    return lng;
  },
  setLng: function (long) {
    lng = long;
  },
  setAddress: function (addres) {
    address = addres;
  },
  getAddress: function () {
    return address;
  },
};

module.exports = Store;
