import React, { Component } from "react";
import FoodMenuItem from "./FoodMenuItem.jsx";
import "./menu_food.css";
import background1 from "../images/b1.jpg";
import background2 from "../images/b2.jpg";
import background3 from "../images/b3.jpg";
import background4 from "../images/b4.jpg";
import background5 from "../images/b5.jpg";

export class menu_food extends Component {
  static counter = -1;
  iconMap = {
    spice: (
      <i
        className="fas fa-pepper-hot"
        title="spicy"
        style={{ color: "red" }}
        title="spicy"
        key="spicy"
      ></i>
    ),
    vegetarian: (
      <i
        className="fas fa-leaf"
        title="vegetarian"
        style={{ color: "green" }}
        title="vegetarian"
        key="vegetarian"
      ></i>
    ),
    group: (
      <i
        className="fas fa-users"
        style={{ color: "#fcdb03" }}
        title="group promo"
        key="group promo"
      ></i>
    ),
    nuts: (
      <img
        src={`${process.env.PUBLIC_URL}/menu/nuts.png`}
        style={{ width: "16px", height: "16px" }}
        alt=""
        title="nuts"
        key="nuts"
      />
    ),
    vegan: (
      <img
        src={`${process.env.PUBLIC_URL}/menu/vegan.svg`}
        style={{ width: "16px", height: "16px" }}
        title="vegan"
        alt=""
        key="vegan"
      />
    ),
    dairy: (
      <img
        src={`${process.env.PUBLIC_URL}/menu/dairy.svg`}
        style={{ width: "16px", height: "16px" }}
        title="dairy"
        alt=""
        key="dairy"
      />
    ),
  };

  state = {
    //upon order just send as a booking stragiht to post, else pending just on client.
    // cartOrders: [{
    //     dishId: 76955679,
    //     quantity: 2,
    //   },],
    index: null,
    backgroundImages: [
      background1,
      background2,
      background3,
      background4,
      background5,
    ],
    promoList: this.props.promoList, //booleans PBV no?
  };
  componentDidMount() {}

  ellipseText = () => {
    let element = document.querySelector(".menu_dish_description");
    let description = element.innerHTML;
    description = description.substring(0, 120) + "...";
    element.innerHTML = description;
    // // console.log(description);
  };

  componentDidUpdate() {
    // // console.log(this.state.index, menu_food.counter);
  }

  updateIndex = () => {
    if (menu_food.counter == 4) {
      menu_food.counter = 0;
    } else {
      menu_food.counter = menu_food.counter + 1;
      // // console.log(menu_food.counter);
    }
  };

  returnComp = (dish, i) => {
    // console.log("YO HERE CHECK IT  1", this.props.weeklyQuantity);
    return (
      <FoodMenuItem
        key={`${dish.id}${this.props.selectedDay}`}
        backgroundImage={this.state.backgroundImages[menu_food.counter]}
        photo={dish.photo}
        stockQuantity={dish.stockQuantity}
        deliveryDates={dish.deliveryDates}
        ratingsAverage={dish.ratingsAverage}
        ratingsQuantity={dish.ratingsQuantity}
        dayPrep={this.props.dayPrep}
        dishName={dish.name}
        iconMap={this.iconMap}
        description={dish.description}
        tags={dish.tags}
        dishId={dish.id}
        price={dish.price}
        allergyDescription={dish.allergyDescription}
        stock={dish.stock}
        groupPromo={dish.groupPromo}
        deliveryTimes={this.props.deliveryTimes}
        pickupAddress={this.props.pickupAddress}
        phone={this.props.phone}
        deliveryDate={this.props.selectedDay}
        setHistoryLink={this.props.setHistoryLink}
        dailyPrep={dish.dailyPrep}
        // pendingOrders={this.props.pendingOrders}
        // stockUpdate={this.props.stockUpdate}
        // updatePendingOrders={this.props.updatePendingOrders}
        userId={this.props.userId}
        fromLogin={this.props.fromLogin}
        user={this.props.user}
        chefId={this.props.chefId}
        name={this.props.firstName}
        index={i}
        unit={dish.unit}
        servingSize={dish.servingSize}
        promoList={this.props.promoList}
        dishNote={dish.note}
        weeklyStock={dish.weeklyStock}
        weeklyQuantity={dish.weeklyQuantity}
        now={dish.now}
        brandPhoto={this.props.brandPhoto}
        note={dish.note}
        todayStock={dish.todayStock}
        setDate={this.props.setDate}
      />
    );
  };

  render() {
    if (this.state.dishes !== null) {
      return (
        <div className="menu_dish_card_cont">
          {this.props.dishes.map((dish, i) => {
            {
              this.updateIndex();
            }
            if (this.props.promoList) {
              if (this.props.indexGroup.has(i)) {
                return this.returnComp(dish, i);
              } else {
                return <div key="lolololol"></div>;
              }
            } else if (!this.props.indexGroup.has(i)) {
              return this.returnComp(dish, i);
            }
          })}
        </div>
      );
    } else {
      return <h1>Loading...</h1>;
    }
  }
}

export default menu_food;
