import React, { Component } from "react";
import "./cartListing.css";

export class cartListing extends Component {
  state = {
    quantity: this.props.dish.quantity,
  }; //quantity error margin top vs bottom. checkout link

  componentDidMount() {
    this.setState({ quantity: this.props.dish.quantity });
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.dish.quantity !== this.props.dish.quantity) {
      this.setState({ quantity: this.props.dish.quantity });
    }
  };

  reduceQuantity = (id, dishId, quantity, index) => {
    // console.log("FLOOOODD quant red");
    if (quantity === 0) return;
    this.props.updateCartOrder(id, dishId, quantity - 1, index);
    this.setState({ quantity: this.state.quantity - 1 });
  };

  capitalizeFirstLetter(string) {
    let str = string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
    return str;
  }

  addQuantity = (id, dishId, quantity, index) => {
    // console.log("FLOOOODD quant add");

    if (quantity === 5) {
      alert(
        "We have capped the number of orders per dish at 5 on tizaar at the moment."
      );
    }
    this.props.updateCartOrder(id, dishId, quantity + 1, index);
    this.setState({ quantity: this.state.quantity + 1 });
  };

  render() {
    return (
      <div className="full_description flex_cont">
        <p className="dish_name_list">
          {this.capitalizeFirstLetter(this.props.dish.dish.name)}
        </p>
        <div className="order_cart_description">
          <div
            onClick={() =>
              this.reduceQuantity(
                this.props.order._id,
                this.props.dish.dish._id,
                this.state.quantity,
                this.props.indexOrder
              )
            }
          >
            <i className="fas fa-minus-circle"></i>
          </div>
          {this.state.quantity}
          <div
            onClick={() =>
              this.addQuantity(
                this.props.order._id,
                this.props.dish.dish._id,
                this.state.quantity,
                this.props.indexOrder
              )
            }
          >
            <i className="fas fa-plus-circle"></i>
          </div>
        </div>
        <p style={{ fontSize: "smaller" }}>
          {this.props.dish.dish.price * this.state.quantity}P
        </p>
      </div>
    );
  }
}

export default cartListing;
