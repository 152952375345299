import React, { Component } from "react";
import COTF from "./COTF/cotf.jsx";
import Filtering from "./filtering/filtering";
import FoodGrid from "./FoodGrid/foodgrid";
import SearchHeader from "./SearchHeader/searchheader";
import cuisineFilters from "./filtering/cuisineFilters";
import axios from "axios";
import querystring from "querystring";
import Store from "../Store";
import Select from "react-select";
import DateLogic from "../../util/dateFunctions";
import Alert from "../alert/alert";
import { Helmet } from "react-helmet";
import "./media_bazaar.css";
import "./bazaar.css";

export class bazaar extends Component {
  state = {
    chefs: null,
    dishes: null,
    filterObj: {},
    searchString: "",
    type: "chefs",
    page: 1,
    pageLoading: false,
    foodLoading: false,
    prevBottom: null,
    stopPage: false,
    menuDates: [],
    open: false,
    sort: true,
    selected: "near",
    main: "The Cooks",
  }; //add loading for this too and pass from apply filter to here for queries.  Then pass to food grid.

  componentDidMount() {
    // window.scrollTo(0, 0);
    // // console.log("axios");
    // console.log("adding event listener");
    document.addEventListener("scroll", this.trackScrolling);
    document.addEventListener("click", this.handleClickOutside, false);
    this.axiosCall();
    // // console.log("scroll");
    // // console.log("SCROLL POST", this.state);
    // // console.log("adding event listener");
  }

  handleClickOutside = (e) => {
    // // console.log("here", e.target, this.popup, this.popup.contains(e.target));
    if (this.popup && !this.popup.contains(e.target) && this.state.open) {
      this.setState({ open: false });
    }
  };

  componentDidUpdate() {}

  componentWillUnmount() {
    // console.log("removing event listener");
    document.removeEventListener("scroll", this.trackScrolling);
    document.removeEventListener("click", this.handleClickOutside, false);
  }

  trackScrolling = () => {
    const wrappedElement = document.getElementById("container_ba");
    // // console.log("I am here");
    if (this.isBottom(wrappedElement) && !this.state.pageLoading) {
      // // console.log("container_ba bottom reached");
      // // console.log("removing event listener");
      // document.removeEventListener("scroll", this.trackScrolling);
      this.setState({ page: this.state.page + 1 });
      // // console.log("adding event listener");
      // this.setState({ pageLoading: true });
      this.axiosCall();
      //   if (!this.state.stopPage)
      //     document.addEventListener("scroll", this.trackScrolling);
      //   // this.setState({ pageLoading: false });
    }
  };

  isBottom(el) {
    // // console.log("el", el, "exp value: true", this.state.stopPage);
    if (!el) return false;
    let bottom = el.getBoundingClientRect();
    let height = window.innerHeight;
    return bottom.bottom - 20 < height && !this.state.stopPage;
  }

  // componentDidUpdate() {
  //   this.axiosCall()
  // }

  onBackNav = () => {
    let filterObj = Store.getQuery();
    // if (!filterObj.baker) filterObj.baker = false;
    // if (!filterObj.pantry) filterObj.pantry = false;
    this.setState({ filterObj });
    return filterObj;
  };

  axiosChefs(queryString, method, data) {
    // console.log(
    //   data,
    //   `${
    //     process.env.NODE_ENV === "production"
    //       ? process.env.REACT_APP_URL
    //       : "http://localhost:8080"
    //   }/api/v1/users/getChefs?${queryString}&page=${this.state.page}`
    // );
    if (this.state.page > 1) this.setState({ pageLoading: true });
    axios({
      method: method,
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/users/getChefs?${queryString}&page=${this.state.page}&type=otm`,
      data: data,
      withCredentials: true,
    }).then((response) => {
      if (response.data.status === "success") {
        // // console.log(response.data, response.data.data.data);
        let chefs = this.state.chefs ? [...this.state.chefs] : null;
        if (response.data.data.data.length === 0 && this.state.page > 1) {
          // console.log("removing event listener");
          document.removeEventListener("scroll", this.trackScrolling); //is this taking time?
          this.setState({
            foodLoading: false,
            stopPage: true,
            pageLoading: false,
          });
          // // console.log("THE [] DATA IS HERE", this.state.chefs);
        } else {
          if (chefs && this.state.page > 1) {
            this.setState({
              chefs: chefs.concat(response.data.data.data),
              foodLoading: false,
              pageLoading: false,
            });
          } else {
            this.setState({
              chefs: response.data.data.data,
              foodLoading: false,
              pageLoading: false,
            });
          }
          // console.log("adding event listener");
          document.addEventListener("scroll", this.trackScrolling);
        }
      } else {
        this.setState({ searchString: "", foodLoading: false });
        alert(
          "There was an issue regarding getting the chefs. Please try again later!"
        );
      }
    });
  }

  axiosCall = () => {
    if (!this.state.foodLoading) this.setState({ foodLoading: true });
    // // console.log("GET STORE", Store.getQuery());
    // console.log("initial filterObj", this.state.filterObj);
    let filterObj =
      Object.keys(this.state.filterObj).length === 0
        ? this.onBackNav()
        : { ...this.state.filterObj };
    // console.log("initial filterObj", filterObj);

    if (!filterObj.baker && !filterObj.pantry && !filterObj.cook) {
      filterObj.cook = true;
      console.log("I AM HEREEE");
      // console.log("initial filterObj", filterObj);
      delete filterObj.baker;
    }
    // if (
    //   (filterObj.baker === null || filterObj.baker === undefined) &&
    //   !filterObj.pantry
    // )
    //   filterObj.baker = false; //NO NEED FOR PANTRY
    // // console.log("FILTERED OBJECT: ", filterObj);
    if (this.state.searchString !== "" && this.state.type === "chefs") {
      // filterObj = {};
      filterObj.name = this.state.searchString.toLowerCase(); //only search for the homechef
    }
    filterObj.lat = this.props.user
      ? this.props.user.location.coordinates[1]
      : Store.getLat();
    filterObj.lng = this.props.user
      ? this.props.user.location.coordinates[0]
      : Store.getLng();
    if (this.state.menuDates.length > 0)
      filterObj.menuDates = this.state.menuDates;
    //SORTING FUNCTIONALITY
    if (this.state.selected === "near") {
      filterObj.near = true;
    } else {
      delete filterObj.near;
    }
    let queryString = querystring.stringify(filterObj);
    // // console.log("TYPE: ", this.state.type, this.state.searchString);
    if (
      this.state.type === "chefs" ||
      (this.state.type === "dishes" && this.state.searchString === "")
    ) {
      // console.log("QUERYSTRING,", queryString);
      this.axiosChefs(queryString, "get", null); //TRY THIS!
    } else {
      //sotring dishes as chefs as well.
      axios({
        method: "get",
        url: `${
          process.env.NODE_ENV === "production"
            ? process.env.REACT_APP_URL
            : "http://localhost:8080"
        }/api/v1/dishes/search?name=${this.state.searchString.toLowerCase()}`,
        withCredentials: true,
      }).then((response) => {
        if (response.data.status === "success") {
          let dishes = response.data.data.dishes;
          // // console.log("dishes", dishes);
          this.axiosChefs(queryString, "post", { ids: dishes });
        } else {
          this.setState({ searchString: "", foodLoading: false });
          alert(
            "There was an issue regarding getting the dishes. Please try again later!"
          );
        }
      });
    }
  };

  setFilterObj = async (filterObj) => {
    // // console.log("FINAL STAGE: ", filterObj);
    // // console.log(
    //   "state equality 1",
    //   this.state === this.state,
    //   this.state == this.state
    // );
    if (!filterObj.baker && !filterObj.cook && !filterObj.pantry) {
      let prevQuery = Store.getQuery();
      if (prevQuery.baker) filterObj.baker = true;
      if (prevQuery.cook) filterObj.cook = true;
      if (prevQuery.pantry) filterObj.pantry = true;
    }

    await this.setState({
      filterObj,
      page: 1,
      stopPage: false,
      chefs: [],
      dishes: [],
    });
    document.addEventListener("scroll", this.trackScrolling);
    Store.saveQuery(filterObj); //for back navigation!
    // const queryString = querystring.stringify(this.state.filterObj);
    // // console.log("QUERY STRING", querystring);
    this.axiosCall();
  };

  setType = (type) => {
    if (type === this.state.type) return;
    if (type === "chefs") {
      this.setState({ type, searchString: "" });
    } else {
      this.setState({ type });
    }
  };

  setSearch = async (value) => {
    // // console.log("search val", value);
    // let filterObj = { ...this.state.filerObj };
    // delete filterObj.cook;
    // delete filterObj.baker;
    // delete filterObj.pantry;
    await this.setState({ searchString: value, page: 1 });
    this.axiosCall();
  };
  // setBazaarState = (bazaarState) => {
  //   this.setState({ bazaarState });
  // };

  setMain = async (main) => {
    await this.setState({ foodLoading: true });
    if (this.state.main === main) {
      return;
    }
    let filterObj = this.state.filterObj;
    delete filterObj.cuisine;
    // cuisineFilters.selected_cuisine = "All";
    await this.setState({
      main: main,
      chefs: [],
      dishes: [],
      filterObj,
    });
  };

  miniFloat = () => {
    return (
      <div
        className="mini_float" //CHECK
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/landing/chef.png)`,
        }}
      ></div>
    );
  };

  removeElement = (array, day) => {
    let returned = [];
    // console.log(day);
    Array.from(array).forEach((e) => {
      if (e !== day) {
        returned.push(e);
      }
    });
    // console.log(returned);
    return returned;
  };

  updateSelect = async (selected) => {
    // console.log(selected.value);
    await this.setState({
      selected: selected.value,
      page: 1,
      chefs: [],
      dishes: [],
      stopPage: false,
    });
    this.axiosCall();
  };

  options = [
    { value: "near", label: "Sort by distance" },
    { value: "ratings", label: "Sort by weighted ratings" },
  ];

  filterDates = () => {};

  render() {
    if (this.state.chefs || this.state.dishes) {
      return (
        <div className="container_nfs" id="container_ba">
          <Helmet>
            <title>tizaar - tizaar's Food Bazaar</title>
            <meta
              name="description"
              content="Tickle your taste buds at tizaar's food bazaar and unconver hidden food delights right in your very neighborhood!"
            />
          </Helmet>
          <SearchHeader
            type={this.state.type}
            searchFor={this.setSearch}
            setType={this.setType}
          />
          <COTF />
          <Filtering
            setFilterState={this.setFilterObj}
            axiosCall={this.setFilterObj}
            setMain={this.setMain}
            // cuisine={this.state.filterObj.cuisine}
          />
          <div className="button_filter_wrappers" style={{ textAlign: "left" }}>
            <button
              className="selector_date"
              onClick={(e) => {
                e.stopPropagation();
                this.setState({ open: !this.state.open });
              }}
            >
              Select a day <i className="fas fa-sort-down"></i>
            </button>
            {this.state.open ? (
              <div
                className="date_select_popup"
                style={{ fontFamily: "landingFontTwo" }}
                ref={(node) => (this.popup = node)}
              >
                {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map(
                  (day) => {
                    return (
                      <div
                        className="flex_cont bazaar_dates"
                        style={{ marginBottom: "10px" }}
                        key={day}
                      >
                        <p className="day_label">{`Menus for ${day}`}</p>
                        <input
                          type="checkbox"
                          name={`${day}_filter_day_input`}
                          id={`${day}_filter_day_input`}
                          value={day}
                          checked={this.state.menuDates.includes(day)}
                          onClick={() => {
                            let menuDates = [...this.state.menuDates];
                            if (menuDates.includes(day)) {
                              menuDates = this.removeElement(menuDates, day);
                            } else {
                              menuDates.push(day);
                            }
                            this.setState({ menuDates });
                          }}
                        />
                      </div>
                    );
                  }
                )}
                <div style={{ textAlign: "center" }}>
                  <button
                    className="apply_dates"
                    onClick={async () => {
                      await this.setState({ page: 1, open: false });
                      this.axiosCall();
                    }}
                  >
                    Apply
                  </button>
                </div>
              </div>
            ) : (
              <div></div>
            )}
            <Select
              color="black"
              placeholder={`Sort by ${
                this.state.sort ? "distance" : "weighted rating"
              }`}
              isSearchable={false}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "orange",
                  primary: "black",
                },
              })}
              className="sorting_selector"
              options={this.options}
              onChange={this.updateSelect}
            ></Select>
          </div>
          {!(this.state.foodLoading && this.state.page === 1) ? (
            <FoodGrid
              chefs={this.state.chefs}
              foodLoading={this.state.foodLoading}
              pageLoading={this.state.pageLoading}
              miniFloat={this.miniFloat}
              main={this.state.main}
              searchString={
                this.state.searchString !== null &&
                this.state.searchString !== null &&
                this.state.searchString !== ""
              }
            />
          ) : (
            this.miniFloat()
          )}
        </div>
      );
    } else {
      return (
        <div className="fullheight">
          <div
            className="chef_float"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/landing/chef.png)`,
            }}
          ></div>
        </div>
      );
    }
  }
}

export default bazaar;
