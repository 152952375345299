import React, { Component } from "react";
import "./homestyle.css";
import LocationInput from "../locationInput/locationInput";
import { Helmet } from "react-helmet";
import RatingsPopup from "../ratings/ratingsPopup";
const mainPhoto = require(`./images/main.jpg`).default;

export class landing extends Component {
  state = {
    hide1: false,
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ hide1: true });
    }, 5000);
  }

  render() {
    return (
      <div className={"body massive_height flex_center"}>
        <div
          className="chef_float_no_fix"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/landing/chef.png)`,
          }}
        ></div>
        <div className="line_1_wrapper">
          {/* <i class="fas fa-user-secret" style={{ color: "white" }}></i> */}
          <div className="line-1">
            <h1 class={this.state.hide1 ? "main_tag pause" : "main_tag"}>
              tizaar OTM
            </h1>
            <p></p>
          </div>
          <p style={{ marginBottom: "40px" }}>
            Your favorite restaurants' secret menus exposed
          </p>
          <LocationInput
            updateLocation={this.props.updateUserLocation}
            user={this.props.user}
            date={new Date()}
            landing={true}
          />

          <div
            className="dishes_promo flex_cont flex_wrap"
            style={{ marginTop: "40px" }}
          >
            <div className="food_card_l">
              <img
                src="https://tizaar-foodbazaar.s3.ap-southeast-1.amazonaws.com/dishes/circle-cropped+(4)+(1).png"
                alt=""
                className="dish_img_l"
              />
              <div className="dish_dets">
                <div className="div_l">
                  <h3 className="dish_name_l">Chick Pea Masala Rice Bowl</h3>
                  <p
                    className="small calorie_count gray"
                    style={{ fontSize: "21px" }}
                  >
                    Mantra Bistro
                  </p>
                </div>
              </div>
              <div className="mini_dets">
                <hr style={{ color: "gray" }}></hr>
                <div className="flex_cont fullwidth">
                  <p className="price_tag_l">300P</p>
                  <p className="portion_size">
                    {" "}
                    <span> 1 </span> person
                  </p>
                </div>
              </div>
            </div>
            <div className="food_card_l">
              <img
                src="https://tizaar-foodbazaar.s3.ap-southeast-1.amazonaws.com/dishes/circle-cropped+(5)+(1).png"
                alt=""
                className="dish_img_l"
              />
              <div className="dish_dets">
                <div className="div_l">
                  <h3 className="dish_name_l">Butter Chicken Rice Bowl</h3>
                  <p
                    className="small calorie_count gray"
                    style={{ fontSize: "25px" }}
                  >
                    Mantra Bistro
                  </p>
                </div>
              </div>
              <div className="mini_dets">
                <hr style={{ color: "gray" }}></hr>
                <div className="flex_cont fullwidth">
                  <p className="price_tag_l">300P</p>
                  <p className="portion_size">
                    <span> 1 </span> person
                  </p>
                </div>
              </div>
            </div>
            <div className="food_card_l">
              <img
                src="https://tizaar-foodbazaar.s3.ap-southeast-1.amazonaws.com/circle-cropped+(6)+(1).png"
                alt=""
                className="dish_img_l"
              />
              <div className="dish_dets">
                <div className="div_l">
                  <h3 className="dish_name_l">Rajma Masala Rice Bowl</h3>
                  <p
                    className="small calorie_count gray"
                    style={{ fontSize: "25px" }}
                  >
                    Mantra Bistro
                  </p>
                </div>
              </div>
              <div className="mini_dets">
                <hr style={{ color: "gray" }}></hr>
                <div className="flex_cont fullwidth">
                  <p className="price_tag_l">300P</p>
                  <p className="portion_size">
                    <span> 1 </span> person
                  </p>
                </div>
              </div>
            </div>
            <div className="food_card_l">
              <img
                src="https://tizaar-foodbazaar.s3.ap-southeast-1.amazonaws.com/circle-cropped+(7)+(1).png"
                alt=""
                className="dish_img_l"
              />
              <div className="dish_dets">
                <div className="div_l">
                  <h3 className="dish_name_l">Prawn Curry Rice Bowl</h3>
                  <p
                    className="small calorie_count gray"
                    style={{ fontSize: "25px" }}
                  >
                    Mantra Bistro
                  </p>
                </div>
              </div>
              <div className="mini_dets">
                <hr style={{ color: "gray" }}></hr>
                <div className="flex_cont fullwidth">
                  <p className="price_tag_l">300P</p>
                  <p className="portion_size">
                    <span> 1 </span> person
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default landing;
