import React, { Component } from "react";
import TableCell from "./tableCell";
import "./profile.css";
import axios from "axios";
import LocationInput from "../locationInput/locationInput";
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

export class profile extends Component {
  state = {
    deliveryTimesMon: this.props.user.deliveryTimesMon,
    // ? this.props.user.deliveryTimesMon
    // : [
    //     null,
    //     null,
    //     null,
    //     null,
    //     null,
    //     null,
    //     null,
    //     null,
    //     null,
    //     null,
    //     null,
    //     null,
    //   ]
    deliveryTimesTue: this.props.user.deliveryTimesTue,
    deliveryTimesWed: this.props.user.deliveryTimesWed,
    deliveryTimesThu: this.props.user.deliveryTimesThu,
    deliveryTimesFri: this.props.user.deliveryTimesFri,
    deliveryTimesSat: this.props.user.deliveryTimesSat,
    deliveryTimesSun: this.props.user.deliveryTimesSun,
    name: this.props.user.name ? this.props.user.name : null,
    address: this.props.user.address ? this.props.user.address : null,
    zip: this.props.user.zip ? this.props.user.zip : null,
    phone: this.props.user.phone ? this.props.user.phone : null,
    email: this.props.user.email ? this.props.user.email : null,
    dayPrep: this.props.user.dayPrep ? this.props.user.dayPrep : 1,
    loading: false,
    logout: false,
    aptNumber: this.props.user.aptNumber,
  };

  deliveryDefaults = {
    Mon: [10, 12, 14, 16, 18, 20],
    Tue: [10, 12, 14, 16, 18, 20],
    Wed: [10, 12, 14, 16, 18, 20],
    Thu: [10, 12, 14, 16, 18, 20],
    Fri: [10, 12, 14, 16, 18, 20],
    Sat: [10, 12, 14, 16, 18, 20],
    Sun: [10, 12, 14, 16, 18, 20],
  };

  deliveryDates = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  componentDidMount() {}

  convertTime = (time) => {
    let timeCopy = time;
    let timeTo = timeCopy + 2;
    let stamp = "am";
    let stampCopy = "am";
    if (time >= 12) {
      stamp = "pm";
      if (time > 12) timeCopy = time - 12;
    }
    if (timeTo >= 12) {
      stampCopy = "pm";
      if (timeTo > 12) timeTo = timeTo - 12;
    }
    return `${timeCopy}${stamp} - ${timeTo}${stampCopy}`;
  };

  newTime = (time, date, indexTime, active) => {
    let string = `deliveryTimes${date}`;
    let deliveryTimes = [...this.state[string]];
    if (active) {
      deliveryTimes[indexTime] = time; //when a new user is created must have 12 nulls for each date!
    } else {
      deliveryTimes[indexTime] = null;
    }
    this.setState({ [string]: deliveryTimes });
    // console.log(deliveryTimes);
  };

  setAddress = (e) => {
    this.setState({ address: e.target.value });
  };

  setAptNumber = (e) => {
    this.setState({ aptNumber: e.target.value });
  };

  setPhone = (e) => {
    this.setState({ phone: e.target.value });
  };

  setZip = (e) => {
    this.setState({ zip: e.target.value });
  };

  setEmail = (e) => {
    this.setState({ email: e.target.value });
  };

  patchUser = () => {
    this.setState({ loading: true });
    let user = { ...this.props.user };
    let state = { ...this.state };
    delete state.loading;
    user.phone = this.state.phone;
    user.zip = this.state.zip;
    user.address = this.state.address;
    user.name = this.state.name;
    user.email = this.state.email;
    user.aptNumber = this.state.aptNumber;
    user.dayPrep = this.state.dayPrep;
    user.deliveryTimesMon = this.state.deliveryTimesMon;
    user.deliveryTimesTue = this.state.deliveryTimesTue;
    user.deliveryTimesWed = this.state.deliveryTimesWed;
    user.deliveryTimesThu = this.state.deliveryTimesThu;
    user.deliveryTimesFri = this.state.deliveryTimesFri;
    user.deliveryTimesSat = this.state.deliveryTimesSat;
    user.deliveryTimesSun = this.state.deliveryTimesSun;
    //PATCH REQUEST WITH USER
    axios({
      method: "patch",
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/users/me`,
      data: state,
      withCredentials: true,
    }).then((response) => {
      if (response.data.status === "success") {
        this.props.setUser(user);
        this.setState({ loading: false });
        alert("Your info was succesfully updated :)");
      } else {
        this.setState({ loading: false });
        alert("There was an error in updating your information");
      }
    });
  };

  setDayPrep = (e) => {
    this.setState({ dayPrep: e.target.value });
  };

  logout = () => {
    axios({
      method: "get",
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/users/logout`,
      withCredentials: true,
    }).then((response) => {
      this.props.setUser(null);
      this.setState({ logout: true });
    });
  };

  render() {
    if (!this.state.logout) {
      return (
        <section className="profile_section">
          <Helmet>
            <title>tizaar - My Profile</title>
            <meta
              name="description"
              content="Tickle your taste buds at tizaar's food bazaar and unconver hidden food delights right in your very neighborhood!"
            />
          </Helmet>
          <div className="container_homechef profile_padding">
            <div className="header_profile">
              <p>
                <b>USER PROFILE</b>
              </p>
              <div
                className="update_profile_changes_btn"
                onClick={this.patchUser}
              >
                <b>{!this.state.loading ? "Submit" : "Loading..."}</b>
              </div>
            </div>
            <div className="top_section_cont">
              <div className="profile_card">
                <div
                  className="profile_img image"
                  style={{
                    backgroundImage: `url(${this.props.user.userPhoto})`,
                  }}
                >
                  {/* <div className="edit_profile_img">
                    <i className="fas fa-pencil-alt"></i>
                  </div> */}
                </div>
                <h1>{this.state.name}</h1>
                {this.props.user.role === "chef" ? (
                  <div className="rating_profile_card">
                    <i
                      className="fas fa-star profile_star"
                      style={{ color: "orange" }}
                    ></i>
                    {this.props.user.ratingsAverage}
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <div className="loyalty_card">
                <img
                  src={`${process.env.PUBLIC_URL}/profile/chip.svg`}
                  alt=""
                  className="chip"
                />
                <h1 className="loyalty_title">
                  <b> TIZAAR LOYALTY CARD</b> <span>COMING SOON</span>
                </h1>
                <div className="card_holder_section">
                  <p className="card_holder_title">CARD HOLDER</p>
                  <p className="name_card">
                    <b>{this.props.user.name}</b>
                  </p>
                </div>
                <div className="card_logo_p">
                  <img
                    src={`${process.env.PUBLIC_URL}/landing/chef.png`}
                    alt=""
                    className="chef_logo"
                  />
                </div>
              </div>
            </div>
            {this.props.user.role === "chef" ? (
              <h1
                style={{
                  textAlign: "left",
                  fontFamily: "landingFontTwo",
                  marginBottom: "30px",
                  marginTop: "40px",
                  fontSize: "15px,",
                }}
              >
                WORKING HOURS TIME SCHEDULE
              </h1>
            ) : (
              <div></div>
            )}

            {this.props.user.role === "chef" ? (
              <div className="div">
                <section className="time_table">
                  <table className="table">
                    <tbody>
                      {/* <th scope="row"> */}
                      <tr>
                        <td className="header_table"></td>
                        {this.deliveryDefaults.Mon.map((time, i) => {
                          return (
                            <td className="table_cell" key={i}>
                              {this.convertTime(time)}
                            </td>
                          );
                        })}
                      </tr>
                      {/* </th> */}

                      {this.deliveryDates.map((date, indexDate) => {
                        return (
                          <tr key={indexDate}>
                            <td className="header_table">
                              <h1>{date}</h1>
                            </td>
                            {this.deliveryDefaults[date].map(
                              (time, indexTime) => {
                                //if (indexTime === 0) <td> {date} </td>
                                return (
                                  <TableCell
                                    key={time}
                                    time={time}
                                    date={date}
                                    indexTime={indexTime}
                                    deliveryTimes={
                                      this.state[`deliveryTimes${date}`]
                                    }
                                    clicked={this.newTime}
                                  />
                                );
                              }
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </section>
                {/* <h1 className="header_profile"></h1>
                <input
                    type="number"
                    className="name_input_p"
                    placeholder="How many days will you need to prepare your food"
                    value={this.state.dayPrep ? this.state.dayPrep : 1}
                  />
                  <input
                    type="number"
                    className="name_input_p"
                    placeholder="How many days will you need to prepare your food"
                    value={this.state.dayPrep ? this.state.dayPrep : 1}
                  /> */}
                <h1 className="header_profile">
                  <b>MINIMUM DAILY PREPARATION TIME</b>
                </h1>
                <input
                  type="number"
                  className="name_input_p"
                  placeholder="How many days will you need to prepare your food"
                  min={1}
                  onChange={this.setDayPrep}
                  value={this.state.dayPrep ? this.state.dayPrep : 1}
                />
                <p
                  className="note profile_note"
                  style={{
                    color: "red",
                    fontFamily: "landingFontTwo",
                    fontSize: "15px",
                  }}
                >
                  All orders on tizaar must be made/placed at least a day in
                  advance. This is why the default value of the "MINIMUM DAILY
                  PREPARATION TIME" is 1. For example, if today is Sunday, all
                  orders must be made for Monday or later. You may increase the
                  "MINIMUM DAILY PREPARATION TIME" to any value less than 7. For
                  example, if increased to 2, all orders placed on Sunday would
                  have to be for Tuesday or later.
                </p>
              </div>
            ) : (
              <div></div>
            )}
            <form className="profile_all_inputs">
              <h1 className="header_profile">
                <b>CONTACT DETAILS</b>
              </h1>
              <div className="contact_details">
                <div className="name_input_profile">
                  <p style={{ fontFamily: "landingFontTwo", color: "#4c4b4c" }}>
                    <b>Your name</b>
                  </p>
                  <input
                    type="text"
                    className="name_input_p"
                    placeholder="Your first name"
                    onChange={(e) => this.setState({ name: e.target.value })}
                    value={this.state.name ? this.state.name : ""}
                  />
                </div>
                <div className="phone_input_profile name_input_profile">
                  <p style={{ fontFamily: "landingFontTwo", color: "#4c4b4c" }}>
                    <b>Phone Number</b>
                  </p>
                  <div className="phone_input_div">
                    <span className="phone_input_span">
                      <b> +63</b>
                    </span>
                    <input
                      type="text"
                      className="name_input_p phone_input_p phone_input_comp"
                      placeholder="Your phone number"
                      onChange={this.setPhone}
                      maxLength={10}
                      value={this.state.phone ? this.state.phone : ""}
                    />
                  </div>
                </div>
                {/* <div className="phone_input_profile name_input_profile">
                  <p style={{ fontFamily: "landingFontTwo", color: "#4c4b4c" }}>
                    <b>Email</b>
                  </p>
                  <input
                    type="text"
                    className="name_input_p email_input_p"
                    placeholder="Your email"
                    onChange={this.setEmail}
                    value={this.state.email ? this.state.email : ""}
                  />
                </div> */}
              </div>
              <h1 className="header_profile">
                <b>ADDRESS DETAILS</b>
              </h1>
              <div className="address_details">
                <div className="phone_input_profile address_input_profile">
                  <div>
                    <p
                      style={{
                        fontFamily: "landingFontTwo",
                        color: "#4c4b4c",
                        marginBottom: "20px",
                        marginLeft: "10px",
                      }}
                    >
                      <b>Present Address: {this.state.address}</b>
                    </p>
                    <div className="phone_input_div">
                      <input
                        type="text"
                        className="apt_input_p"
                        placeholder="Your apartment number if applicable i.e 5B"
                        onChange={this.setAptNumber}
                        value={this.state.aptNumber ? this.state.aptNumber : ""}
                      />
                    </div>
                  </div>
                  {/* <input
                  type="text"
                  className="name_input_p address_input_p"
                  placeholder="Street Address, Village, City, State"
                  onChange={this.setAddress}
                  value={this.state.address}
                /> */}
                  <LocationInput
                    updateLocation={this.props.updateUserLocation}
                    user={this.props.user}
                    date={new Date()}
                    homechef={true}
                  />
                </div>
                <div className="phone_input_profile zip_input_profile">
                  <p
                    style={{
                      fontFamily: "landingFontTwo",
                      color: "#4c4b4c",
                      marginBottom: "20px",
                      marginLeft: "10px",
                    }}
                  >
                    <b>ZIP Code</b>
                  </p>
                  <input
                    type="text"
                    className="name_input_p zip_input_p"
                    placeholder="Enter your ZIP Code"
                    onChange={this.setZip}
                    value={this.state.zip ? this.state.zip : ""}
                  />
                </div>
              </div>
            </form>
            <button className="logout_btn" onClick={this.logout}>
              Logout
            </button>
          </div>
        </section>
      );
    } else {
      return (
        <div>
          <Redirect to="/login" />
        </div>
      );
    }
  }
}

export default profile;
