import React, { Component } from "react";
import FoodItem from "./foodItem.jsx";
import "../general_bazaar.css";
import "./foodgrid.css";
import "../media_bazaar.css";

export class foodgrid extends Component {
  state = {
    chefs: [],
  };

  nameMap = {
    "The Cooks": "cooks",
    "The Bakers": "bakers",
    "The Pantry": "sellers",
  };

  componentDidMount() {
    this.setState({ chefs: this.props.chefs });
  }

  componentDidUpdate(prevProps) {
    if (this.props.chefs != prevProps.chefs) {
      this.setState({
        chefs: this.props.chefs ? this.props.chefs : [],
      });
    }
  }

  render() {
    let newObject = "";
    if (this.props.searchString) {
      let nameMap = { ...this.nameMap };
      delete nameMap[this.props.main];
      newObject = Object.keys(nameMap).join(", or ");
    }
    return (
      <div className="main_e-commerce_section">
        <div className="food_container">
          <section className="food-grid">
            {this.state.chefs.length > 0 ? (
              this.state.chefs.map((chef) => {
                return (
                  <FoodItem
                    data={chef}
                    key={chef.id}
                    // miniFloat={this.props.miniFloat}
                    // pageLoading={this.props.pageLoading}
                  />
                );
              })
            ) : !this.props.foodLoading ? (
              <div
                style={{
                  padding: "10px",
                  marginBottom: "20px",
                  marginTop: "30px",
                  fontFamily: "landingFontTwo",
                  textAlign: "center",
                  fontSize: "20px",
                }}
              >
                There don't seem to be any restaurants in your area for your
                above filter at the moment.{" "}
                {this.props.searchString
                  ? `Perhaps try seeing if ${newObject} have what you're searching for. `
                  : ""}
                Sorry!
              </div>
            ) : null}
            {this.props.pageLoading ? this.props.miniFloat() : <div></div>}
          </section>
        </div>
      </div>
    );
  }
}

export default foodgrid;
