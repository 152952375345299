import React, { Component } from "react";
// import "../general_bazaar.css";
import cuisineFilters from "./cuisineFilters";
import "./featureFilters.css";
import Store from "../../Store";
// import "../media_bazaar.css";

export class featureFilters extends Component {
  state = {
    active: false,
  };

  componentDidMount() {
    let active = Store.getActive(this.props.featureName);
    if (active && this.props.featureName === "The Bakers") {
      this.toggleSearchActive();
    } else {
      this.setState({ active });
    }
  }

  toggleSearchActive = async () => {
    const active = !this.state.active;
    // if (active && this.props.featureName === "The Bakers") {
    //   this.props.bakersBool(true);
    //   cuisineFilters.selected_cuisine = "All";
    //   // console.log(`${cuisineFilters.selected_cuisine} HILE`);
    //   await this.props.onBakerChange("All");
    // } else if (this.props.featureName === "The Bakers") {
    //   this.props.bakersBool(false);
    //   cuisineFilters.selected_cuisine = "All";
    //   // console.log(cuisineFilters.selected_cuisine);
    //   await this.props.onBakerChange("All");
    // } else if (active && this.props.featureName === "The Pantry") {
    //   this.props.pantryBool(true);
    //   cuisineFilters.selected_cuisine = "All";
    // } else if (this.props.featureName === "The Pantry") {
    //   this.props.pantryBool(false);
    //   cuisineFilters.selected_cuisine = "All";
    // }
    this.props.onChange(active, this.props.featureName);
    this.setState({ active });
    active
      ? Store.setActive(this.props.featureName)
      : Store.setInactive(this.props.featureName);
  };

  render() {
    var img;
    if (this.props.fileName == null) {
      if (this.props.featureName === "None") {
        img = <i className="fas fa-ban icon_img"></i>;
      } else if (this.props.featureName === "Vegetarian") {
        img = <i className="fas fa-leaf icon_img"></i>;
      } else if (this.props.featureName === "Group Promos") {
        img = <i className="fas fa-users icon_img"></i>;
      }
    } else {
      const icon = require(`../../images/bw_logos/${this.props.fileName}`)
        .default;
      if (this.props.featureName === "price-two") {
        img = (
          <div className="sub_feature">
            <img
              src={icon} //check for all!
              alt={``}
              className="icon_img price_icon"
            />
            <img
              src={icon} //check for all!
              alt={``}
              className="icon_img price_icon"
            />
          </div>
        );
      } else if (this.props.featureName === "price-three") {
        img = (
          <div className="sub_feature">
            <img
              src={icon} //check for all!
              alt={``}
              className="icon_img price_icon"
              style={{ marginRight: "5px" }}
            />
            <img
              src={icon} //check for all!
              alt={``}
              className="icon_img price_icon"
              style={{ marginRight: "5px" }}
            />
            <img
              src={icon} //check for all!
              alt={``}
              className="icon_img price_icon"
            />
          </div>
        );
      } else {
        img = (
          <img
            src={icon} //check for all!
            alt={``}
            className={
              this.props.featureName === "price-one"
                ? "icon_img price_icon"
                : "icon_img"
            }
          />
        );
      }
    }
    // // console.log(`THIS IS IMAGE ${img}`);
    return (
      <div
        className={
          this.state.active
            ? `filter_bubble active_filter`
            : `filter_bubble ${this.props.featureName}`
        }
        onClick={this.toggleSearchActive}
      >
        {img}
        {this.props.featureName.substring(0, 5) != "price" ? (
          <b>{this.props.featureName}</b>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default featureFilters;
